import { render, staticRenderFns } from "./MerchandiseModal.vue?vue&type=template&id=0a34488d"
import script from "./MerchandiseModal.vue?vue&type=script&lang=ts"
export * from "./MerchandiseModal.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/mac/Documents/project/028/028admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0a34488d')) {
      api.createRecord('0a34488d', component.options)
    } else {
      api.reload('0a34488d', component.options)
    }
    module.hot.accept("./MerchandiseModal.vue?vue&type=template&id=0a34488d", function () {
      api.rerender('0a34488d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/merchantdise/components/MerchandiseModal.vue"
export default component.exports