"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.configWarrantIncomeHourApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var configWarrantIncomeHourApi = exports.configWarrantIncomeHourApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/configWarrantIncomeHour',
      params: params
    });
  },
  init: function init(data) {
    return (0, _request.default)({
      url: '/configWarrantIncomeHour/init',
      method: 'post',
      data: data
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/configWarrantIncomeHour/".concat(id, "/update"),
      data: data,
      method: 'post'
    });
  }
};