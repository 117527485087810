"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CardProductTypeTrans = exports.CardProductType = exports.CARD_SUPPORTS = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _CardProductTypeTrans;
var CardProductType;
(function (CardProductType) {
  CardProductType["Mobi"] = "MOBI";
  CardProductType["Vinaphone"] = "VINAPHONE";
  CardProductType["Viettel"] = "VIETTEL";
  CardProductType["GMobile"] = "G_MOBILE";
  CardProductType["Garena"] = "GARENA_GAME";
  CardProductType["Zing"] = "ZING_GAME";
  CardProductType["Vtc"] = "VCOIN_GAME";
  CardProductType["SohaCoin"] = "SOHA_GAME";
  CardProductType["Gate"] = "GATE_GAME";
  CardProductType["FunCard"] = "FUNCA_GAME";
  CardProductType["Kul"] = "KUL_GAME";
  CardProductType["Gosu"] = "GOSU_GAME";
  CardProductType["VietNamMobile"] = "VIETNAM_MOBILE";
})(CardProductType || (exports.CardProductType = CardProductType = {}));
var CardProductTypeTrans = exports.CardProductTypeTrans = (_CardProductTypeTrans = {}, (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_CardProductTypeTrans, CardProductType.Mobi, 'Mobifone'), CardProductType.Viettel, 'Viettel'), CardProductType.Vinaphone, 'Vinaphone'), CardProductType.GMobile, 'Gmobile'), CardProductType.Garena, 'Garena'), CardProductType.Zing, 'Zing'), CardProductType.Vtc, 'Vcoin'), CardProductType.SohaCoin, 'Soha Coin'), CardProductType.Gate, 'Gate'), CardProductType.FunCard, 'Funcash'), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_CardProductTypeTrans, CardProductType.Kul, 'Kul'), CardProductType.Gosu, 'Gosu'), CardProductType.VietNamMobile, 'Vietnam Mobile'));
var CARD_SUPPORTS = exports.CARD_SUPPORTS = [{
  name: CardProductTypeTrans[CardProductType.Viettel],
  // code: CardProductType.Viettel,
  values: [10000, 20000, 50000, 100000, 200000, 500000, 1000000],
  type: CardProductType.Viettel
}, {
  name: CardProductTypeTrans[CardProductType.Mobi],
  // code: CardProductType.Mobi,
  values: [10000, 20000, 50000, 100000, 200000, 500000],
  type: CardProductType.Mobi
}, {
  name: CardProductTypeTrans[CardProductType.Vinaphone],
  // code: CardProductType.Vinaphone,
  values: [10000, 20000, 50000, 100000, 200000, 500000],
  type: CardProductType.Vinaphone
}, {
  name: CardProductTypeTrans[CardProductType.VietNamMobile],
  // code: CardProductType.Vinaphone,
  values: [10000, 20000, 30000, 50000, 100000, 200000, 300000, 500000],
  type: CardProductType.VietNamMobile
}, {
  name: CardProductTypeTrans[CardProductType.GMobile],
  // code: CardProductType.GMobile,
  values: [10000, 20000, 50000, 100000, 200000, 500000],
  type: CardProductType.GMobile
}, {
  name: CardProductTypeTrans[CardProductType.Garena],
  // code: CardProductType.Garena,
  values: [20000, 50000, 100000, 200000, 500000],
  type: CardProductType.Garena
}, {
  name: CardProductTypeTrans[CardProductType.Zing],
  // code: CardProductType.Zing,
  values: [10000, 20000, 50000, 100000, 200000, 500000, 1000000, 2000000, 5000000],
  type: CardProductType.Zing
}, {
  name: CardProductTypeTrans[CardProductType.Vtc],
  // code: CardProductType.Vtc,
  values: [10000, 20000, 50000, 100000, 200000, 500000, 1000000, 2000000, 5000000],
  type: CardProductType.Vtc
}, {
  name: CardProductTypeTrans[CardProductType.SohaCoin],
  // code: CardProductType.SohaCoin,
  values: [10000, 20000, 50000, 100000, 200000, 500000, 1000000, 2000000, 5000000],
  type: CardProductType.SohaCoin
}, {
  name: CardProductTypeTrans[CardProductType.Gate],
  // code: CardProductType.Gate,
  values: [10000, 20000, 50000, 100000, 200000, 500000, 1000000, 2000000, 5000000, 10000000],
  type: CardProductType.Gate
}, {
  name: CardProductTypeTrans[CardProductType.FunCard],
  // code: CardProductType.FunCard,
  values: [10000, 20000, 50000, 100000, 200000, 500000, 1000000, 2000000],
  type: CardProductType.FunCard
}, {
  name: CardProductTypeTrans[CardProductType.Kul],
  // code: CardProductType.Kul,
  values: [10000, 20000, 50000, 100000, 200000, 500000, 1000000, 2000000],
  type: CardProductType.Kul
}, {
  name: CardProductTypeTrans[CardProductType.Gosu],
  // code: CardProductType.Gosu,
  values: [10000, 20000, 50000, 100000, 200000, 500000, 1000000],
  type: CardProductType.Gosu
}];