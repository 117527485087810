import { render, staticRenderFns } from "./DepositStore.vue?vue&type=template&id=40af709a&scoped=true"
import script from "./DepositStore.vue?vue&type=script&lang=ts"
export * from "./DepositStore.vue?vue&type=script&lang=ts"
import style0 from "./DepositStore.vue?vue&type=style&index=0&id=40af709a&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40af709a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/mac/Documents/project/028/028admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('40af709a')) {
      api.createRecord('40af709a', component.options)
    } else {
      api.reload('40af709a', component.options)
    }
    module.hot.accept("./DepositStore.vue?vue&type=template&id=40af709a&scoped=true", function () {
      api.rerender('40af709a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/deposit-store/DepositStore.vue"
export default component.exports