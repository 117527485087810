"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.appFeatureConfigDetailApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var appFeatureConfigDetailApi = exports.appFeatureConfigDetailApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/appFeatureConfigDetail',
      params: params
    });
  },
  updatePosition: function updatePosition(data) {
    return (0, _request.default)({
      url: "/appFeatureConfigDetail/batch/position",
      method: 'patch',
      data: data
    });
  },
  createOrUpdate: function createOrUpdate(data) {
    return (0, _request.default)({
      url: "/appFeatureConfigDetail/createOrUpdate",
      method: 'post',
      data: data
    });
  },
  cloneCityToDistrict: function cloneCityToDistrict(data) {
    return (0, _request.default)({
      url: "/appFeatureConfigDetail/clone",
      method: 'post',
      data: data
    });
  },
  cloneCityToCity: function cloneCityToCity(data) {
    return (0, _request.default)({
      url: "/appFeatureConfigDetail/clone/toCity",
      method: 'post',
      data: data
    });
  }
};