"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.idealCodeApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var idealCodeApi = exports.idealCodeApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/idealCode',
      params: params
    });
  },
  reward: function reward(data) {
    return (0, _request.default)({
      url: '/idealCode/reward',
      data: data,
      method: 'post'
    });
  },
  findToShow: function findToShow(params) {
    return (0, _request.default)({
      url: '/idealCode/all',
      params: params
    });
  }
};