import { render, staticRenderFns } from "./DetailDialog.vue?vue&type=template&id=deae88f2"
import script from "./DetailDialog.vue?vue&type=script&lang=ts"
export * from "./DetailDialog.vue?vue&type=script&lang=ts"
import style0 from "./DetailDialog.vue?vue&type=style&index=0&id=deae88f2&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/mac/Documents/project/028/028admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('deae88f2')) {
      api.createRecord('deae88f2', component.options)
    } else {
      api.reload('deae88f2', component.options)
    }
    module.hot.accept("./DetailDialog.vue?vue&type=template&id=deae88f2", function () {
      api.rerender('deae88f2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/order/components/DetailDialog.vue"
export default component.exports