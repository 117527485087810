"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.idealPrizeApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var idealPrizeApi = exports.idealPrizeApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/idealPrize',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/idealPrize/',
      data: data,
      method: 'post',
      timeout: 60 * 1000 * 2 //2phut
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: '/idealPrize/' + id
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/idealPrize/".concat(id),
      data: data,
      method: 'patch',
      timeout: 60 * 1000 * 2 //2phut
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/idealPrize/".concat(id),
      method: 'delete'
    });
  }
};