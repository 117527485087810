"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProviderTransactionTypeTrans = exports.ProviderTransactionType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var ProviderTransactionType;
(function (ProviderTransactionType) {
  ProviderTransactionType["Order"] = "ORDER";
  ProviderTransactionType["CommissionOrder"] = "COMMISSION_ORDER";
  ProviderTransactionType["RejectWithdraw"] = "REJECT_WITHDRAW";
  ProviderTransactionType["Withdraw"] = "WITHDRAW";
})(ProviderTransactionType || (exports.ProviderTransactionType = ProviderTransactionType = {}));
var ProviderTransactionTypeTrans = exports.ProviderTransactionTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, ProviderTransactionType.CommissionOrder, "Tiền chiết khấu đơn hàng"), ProviderTransactionType.Order, "Tiền đơn hàng"), ProviderTransactionType.RejectWithdraw, "Hủy lệnh rút"), ProviderTransactionType.Withdraw, "Rút tiền");