"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.idealPrizeTypeTrans = exports.IdealProductPrizeType = exports.IdealPrizeType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var IdealPrizeType;
(function (IdealPrizeType) {
  IdealPrizeType["Main"] = "MAIN";
  IdealPrizeType["Sub"] = "SUB";
})(IdealPrizeType || (exports.IdealPrizeType = IdealPrizeType = {}));
var IdealProductPrizeType;
(function (IdealProductPrizeType) {
  IdealProductPrizeType["Single"] = "SINGLE";
  IdealProductPrizeType["Multiple"] = "MULTIPLE";
})(IdealProductPrizeType || (exports.IdealProductPrizeType = IdealProductPrizeType = {}));
var idealPrizeTypeTrans = exports.idealPrizeTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, IdealPrizeType.Main, {
  title: 'Giải chính'
}), IdealPrizeType.Sub, {
  title: 'Giải phụ'
});