"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProviderWithdrawStatusTrans = exports.ProviderWithdrawStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var ProviderWithdrawStatus;
(function (ProviderWithdrawStatus) {
  ProviderWithdrawStatus["Pending"] = "PENDING";
  ProviderWithdrawStatus["Approve"] = "APPROVE";
  ProviderWithdrawStatus["Reject"] = "REJECT";
})(ProviderWithdrawStatus || (exports.ProviderWithdrawStatus = ProviderWithdrawStatus = {}));
var ProviderWithdrawStatusTrans = exports.ProviderWithdrawStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, ProviderWithdrawStatus.Pending, {
  label: "Chưa duyệt",
  color: '',
  value: ProviderWithdrawStatus.Pending
}), ProviderWithdrawStatus.Approve, {
  label: "Đã duyệt",
  color: 'success',
  value: ProviderWithdrawStatus.Approve
}), ProviderWithdrawStatus.Reject, {
  label: "Bị từ chối",
  color: 'danger',
  value: ProviderWithdrawStatus.Reject
});