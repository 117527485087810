"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PopupPage = exports.PopupModeTrans = exports.PopupMode = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var PopupPage;
(function (PopupPage) {
  PopupPage["Home"] = "HOME";
})(PopupPage || (exports.PopupPage = PopupPage = {}));
var PopupMode;
(function (PopupMode) {
  PopupMode["IdealProduct"] = "IDEAL_PRODUCT";
  PopupMode["AppFeature"] = "APP_FEATURE";
})(PopupMode || (exports.PopupMode = PopupMode = {}));
var PopupModeTrans = exports.PopupModeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, PopupMode.AppFeature, "Tính năng app"), PopupMode.IdealProduct, "Sản phẩm Fclass Draw");