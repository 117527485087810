"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.conversationApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var conversationApi = exports.conversationApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/conversation",
      params: params
    });
  },
  findOne: function findOne(id, params) {
    return (0, _request.default)({
      url: "/conversation/".concat(id, "/message"),
      params: params
    });
  },
  findOneConversation: function findOneConversation(id) {
    return (0, _request.default)({
      url: "/conversation/".concat(id)
    });
  },
  chat: function chat(id, data) {
    return (0, _request.default)({
      url: "/conversation/".concat(id, "/chat"),
      method: "post",
      data: data
    });
  },
  startConversationWithCustomer: function startConversationWithCustomer(customerId) {
    return (0, _request.default)({
      url: "/conversation/customer",
      method: "post",
      data: {
        customerId: customerId
      }
    });
  },
  chatDriver: function chatDriver(id, data) {
    return (0, _request.default)({
      url: "/conversation/".concat(id, "/driver/chat"),
      method: "post",
      data: data
    });
  },
  deleteMessage: function deleteMessage(id) {
    return (0, _request.default)({
      url: "/conversation/".concat(id),
      method: "delete"
    });
  }
};