"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PaymentTypeTrans = exports.PaymentType = exports.PaymentStatusTransFull = exports.PaymentStatusTrans = exports.PaymentStatus = exports.PaymentByTrans = exports.PaymentBy = exports.BalanceType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _PaymentTypeTrans;
var PaymentType;
(function (PaymentType) {
  PaymentType["Cash"] = "CASH";
  PaymentType["Point"] = "POINT";
  PaymentType["VnPay"] = "VNPAY";
  PaymentType["Company"] = "COMPANY";
  PaymentType["Balance"] = "BALANCE";
  PaymentType["Promotion"] = "PROMOTION";
  PaymentType["Payoo"] = "PAYOO";
  PaymentType["Momo"] = "MOMO";
  PaymentType["MAIN"] = "MAIN";
  PaymentType["BalancePromotion"] = "BALANCE_PROMOTION";
  PaymentType["TransferBank"] = "TRANSFER_BANK";
  PaymentType["Wallet"] = "WALLET";
  PaymentType["ViettelPay"] = "VIETTEL_PAY";
})(PaymentType || (exports.PaymentType = PaymentType = {}));
var PaymentTypeTrans = exports.PaymentTypeTrans = (_PaymentTypeTrans = {}, (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_PaymentTypeTrans, PaymentType.Balance, 'Tủ chính'), PaymentType.MAIN, 'Tủ chính'), PaymentType.Promotion, 'Tủ khuyến mãi'), PaymentType.BalancePromotion, 'Tủ khuyến mãi'), PaymentType.VnPay, 'T.toán VNPAY'), PaymentType.Company, 'Tủ công ty'), PaymentType.Cash, 'Tiền mặt'), PaymentType.Payoo, 'T.toán payoo'), PaymentType.Momo, 'T.toán momo'), PaymentType.TransferBank, 'Chuyển khoản ngân hàng'), (0, _defineProperty2.default)((0, _defineProperty2.default)(_PaymentTypeTrans, PaymentType.Wallet, 'Quỹ nhóm'), PaymentType.ViettelPay, 'T.toán ViettelPay'));
var PaymentStatus;
(function (PaymentStatus) {
  PaymentStatus["Pending"] = "PENDING";
  PaymentStatus["Complete"] = "COMPLETE";
  PaymentStatus["Fail"] = "FAIL";
})(PaymentStatus || (exports.PaymentStatus = PaymentStatus = {}));
var PaymentBy;
(function (PaymentBy) {
  PaymentBy["Sender"] = "SENDER";
  PaymentBy["Receiver"] = "RECEIVER";
})(PaymentBy || (exports.PaymentBy = PaymentBy = {}));
var BalanceType;
(function (BalanceType) {
  BalanceType["Main"] = "MAIN";
  BalanceType["Promotion"] = "PROMOTION";
})(BalanceType || (exports.BalanceType = BalanceType = {}));
var PaymentByTrans = exports.PaymentByTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, PaymentBy.Sender, "Người gửi"), PaymentBy.Receiver, "Người nhận");
var PaymentStatusTrans = exports.PaymentStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, PaymentStatus.Pending, 'Chờ thanh toán'), PaymentStatus.Complete, 'Hoàn tất'), PaymentStatus.Fail, 'Thất bại');
var PaymentStatusTransFull = exports.PaymentStatusTransFull = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, PaymentStatus.Pending, {
  type: "",
  label: "Chờ thanh toán",
  value: PaymentStatus.Pending
}), PaymentStatus.Complete, {
  type: "success",
  label: "Hoàn tất",
  value: PaymentStatus.Complete
}), PaymentStatus.Fail, {
  type: "danger",
  label: "Thất bại",
  value: PaymentStatus.Fail
});