"use strict";

var _interopRequireWildcard = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.app = void 0;
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("/Users/mac/Documents/project/028/028admin/node_modules/core-js/modules/es.array.iterator.js");
require("/Users/mac/Documents/project/028/028admin/node_modules/core-js/modules/es.promise.js");
require("/Users/mac/Documents/project/028/028admin/node_modules/core-js/modules/es.object.assign.js");
require("/Users/mac/Documents/project/028/028admin/node_modules/core-js/modules/es.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("@/styles/index.scss");
require("@/plugins/click-outside");
var _App = _interopRequireDefault(require("./App.vue"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("@/plugins/OneSignal");
var _vi = _interopRequireDefault(require("element-ui/lib/locale/lang/vi"));
var _locale = _interopRequireDefault(require("element-ui/lib/locale"));
require("./icons");
require("./permission");
require("./utils/error-log");
require("@/plugins/google-maps");
var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));
var _vueHtmlToPaper = _interopRequireDefault(require("vue-html-to-paper"));
var _SingleImage = _interopRequireDefault(require("@/components/Upload/SingleImage.vue"));
var _index2 = _interopRequireDefault(require("@/components/Pagination/index.vue"));
require("@/plugins/v-viewer");
require("@/plugins/html-to-paper");
var filters = _interopRequireWildcard(require("./filters"));
var _date = require("@/utils/date");
var _format = require("@/utils/format");
require("@/plugins/input-mask");
var _fontawesomeSvgCore = require("@fortawesome/fontawesome-svg-core");
var _freeSolidSvgIcons = require("@fortawesome/free-solid-svg-icons");
var _vueFontawesome = require("@fortawesome/vue-fontawesome");
var _url = require("./utils/url");
var _permission2 = require("./utils/permission");
// a modern alternative to CSS resets

// import "./styles/element-variables.scss";
// global css

// icon
// permission control
// error log
//google maps

_vue.default.component('single-image', _SingleImage.default);
_vue.default.component('Pagination', _index2.default);

// global filters

_vue.default.prototype.$formatDateTime = _date.formatDateTime;
_vue.default.prototype.$formatDate = _date.formatDate;
_vue.default.prototype.$formatNumberVN = _format.formatNumberVN;
_vue.default.prototype.$formatCurrency = _format.formatCurrency;
_vue.default.prototype.$cloudFrontUrl = process.env.VUE_APP_CLOUD_FRONT_URL;
_vue.default.prototype.$baseUrl = process.env.VUE_APP_BASE_API;
_vue.default.prototype.$baseUrlMedia = process.env.VUE_APP_BASE_URL_MEDIA;
_vue.default.prototype.$url = _url.url;
_vue.default.prototype.$checkAction = _permission2.checkAction;
_fontawesomeSvgCore.library.add(_freeSolidSvgIcons.faChevronRight, _freeSolidSvgIcons.faChevronLeft, _freeSolidSvgIcons.faUserSecret, _freeSolidSvgIcons.faDollarSign, _freeSolidSvgIcons.faTicketAlt, _freeSolidSvgIcons.faUserPlus, _freeSolidSvgIcons.faUsers, _freeSolidSvgIcons.faCar, _freeSolidSvgIcons.faMoneyCheckAlt, _freeSolidSvgIcons.faPercentage, _freeSolidSvgIcons.faHandHoldingUsd);
_vue.default.component("font-awesome-icon", _vueFontawesome.FontAwesomeIcon);
_locale.default.use(_vi.default);
_vue.default.use(_elementUi.default, {
  size: "small" // set element-ui default size
});
_vue.default.use(_vueClipboard.default);
_vue.default.use(_vueHtmlToPaper.default, {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: ["https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css", "https://unpkg.com/kidlat-css/css/kidlat.css"]
});
// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
_vue.default.prototype.$devMode = process.env.VUE_APP_IS_DEV == "true";
var app = exports.app = new _vue.default({
  el: "#app",
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});