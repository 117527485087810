"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AgentWithdrawStatusTrans = exports.AgentWithdrawStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var AgentWithdrawStatus;
(function (AgentWithdrawStatus) {
  AgentWithdrawStatus["Pending"] = "PENDING";
  AgentWithdrawStatus["Approve"] = "APPROVE";
  AgentWithdrawStatus["Reject"] = "REJECT";
})(AgentWithdrawStatus || (exports.AgentWithdrawStatus = AgentWithdrawStatus = {}));
var AgentWithdrawStatusTrans = exports.AgentWithdrawStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, AgentWithdrawStatus.Pending, {
  label: "Chưa duyệt",
  color: '',
  value: AgentWithdrawStatus.Pending
}), AgentWithdrawStatus.Approve, {
  label: "Đã duyệt",
  color: 'success',
  value: AgentWithdrawStatus.Approve
}), AgentWithdrawStatus.Reject, {
  label: "Bị từ chối",
  color: 'danger',
  value: AgentWithdrawStatus.Reject
});