"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.voucherApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var voucherApi = exports.voucherApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/voucher",
      params: params
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: "/voucher/".concat(id)
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/voucher",
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/voucher/".concat(id, "/update"),
      method: 'post',
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/voucher/".concat(id, "/delete"),
      method: 'post'
    });
  },
  assignCustomer: function assignCustomer(id, data) {
    return (0, _request.default)({
      url: "/voucher/".concat(id, "/customer-assign"),
      method: 'post',
      data: data
    });
  },
  assignCustomerBatch: function assignCustomerBatch(id, data) {
    return (0, _request.default)({
      url: "/voucher/".concat(id, "/customer-assign-batch"),
      method: 'post',
      data: data
    });
  },
  customers: function customers(id, params) {
    return (0, _request.default)({
      url: "/voucher/".concat(id, "/customers"),
      params: params
    });
  }
};