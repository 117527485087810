"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es.object.to-string.js");
var _address = require("@/api/address");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      form: {},
      visible: false,
      loading: false
    };
  },
  methods: {
    updateData: function updateData() {
      var _this = this;
      this.loading = true;
      _address.addressApi.updateCity(this.form.id, {
        city: this.form
      }).then(function () {
        _this.$notify.success({
          title: "Thông báo",
          message: "Cập nhật thành công"
        });
        _this.$emit("submit:success");
        _this.visible = false;
      }).finally(function () {
        return _this.loading = false;
      });
    },
    handleUpdate: function handleUpdate(form) {
      this.form = (0, _objectSpread2.default)({}, form);
      this.visible = true;
    }
  }
};