import { render, staticRenderFns } from "./EduLectureTable.vue?vue&type=template&id=5bed14d4&scoped=true"
import script from "./EduLectureTable.vue?vue&type=script&lang=ts"
export * from "./EduLectureTable.vue?vue&type=script&lang=ts"
import style0 from "./EduLectureTable.vue?vue&type=style&index=0&id=5bed14d4&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bed14d4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/mac/Documents/project/028/028admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5bed14d4')) {
      api.createRecord('5bed14d4', component.options)
    } else {
      api.reload('5bed14d4', component.options)
    }
    module.hot.accept("./EduLectureTable.vue?vue&type=template&id=5bed14d4&scoped=true", function () {
      api.rerender('5bed14d4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/edu-lecture/EduLectureTable.vue"
export default component.exports