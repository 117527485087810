"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DictionaryTypeTrans = exports.DictionaryType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var DictionaryType;
(function (DictionaryType) {
  DictionaryType["BookingView"] = "BOOKING_VIEW";
  DictionaryType["BookingRoomType"] = "BOOKING_ROOM_TYPE";
  DictionaryType["BookingBedType"] = "BOOKING_BED_TYPE";
  DictionaryType["BookingService"] = "BOOKING_SERVICE";
  DictionaryType["SponsorGiftAge"] = "SPONSOR_GIFT_AGE";
  DictionaryType["SponsorGiftObject"] = "SPONSOR_GIFT_OBJECT";
})(DictionaryType || (exports.DictionaryType = DictionaryType = {}));
var DictionaryTypeTrans = exports.DictionaryTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, DictionaryType.BookingView, 'View phòng'), DictionaryType.BookingRoomType, 'Loại phòng'), DictionaryType.BookingBedType, 'Loại giường'), DictionaryType.BookingService, 'Dịch vụ'), DictionaryType.SponsorGiftAge, 'Độ tuổi'), DictionaryType.SponsorGiftObject, 'Đối tượng nhận học bổng');