"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _UploadExcel = _interopRequireDefault(require("@/components/UploadExcel"));
var _exportExcelLang = require("./export-excel-lang");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    UploadExcel: _UploadExcel.default
  },
  data: function data() {
    return {
      column: "",
      json: ""
    };
  },
  methods: {
    handleGetData: function handleGetData() {
      var tmpJson = JSON.parse(this.json);
      var data = [];
      Object.keys(tmpJson).forEach(function (k) {
        data.push({
          key: k,
          value: tmpJson[k]
        });
      });
      (0, _exportExcelLang.exportExcelLang)(data, this.column || "vi");
    }
  }
};