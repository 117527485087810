"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _enum = require("@/utils/enum");
var _DepositStore = _interopRequireDefault(require("./DepositStore.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    DepositStore: _DepositStore.default
  },
  data: function data() {
    return {
      BalanceType: _enum.BalanceType
    };
  }
};