"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DriverNotificationTypeTrans = exports.DriverNotificationType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var DriverNotificationType;
(function (DriverNotificationType) {
  DriverNotificationType["News"] = "NEWS";
  DriverNotificationType["Order"] = "ORDER";
  //Open screen on mobile
  DriverNotificationType["OpenShareScreen"] = "OPEN_SHARE_SCREEN";
  DriverNotificationType["OpenApp"] = "OPEN_APP";
  DriverNotificationType["OpenNews"] = "OPEN_NEWS";
})(DriverNotificationType || (exports.DriverNotificationType = DriverNotificationType = {}));
var DriverNotificationTypeTrans = exports.DriverNotificationTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, DriverNotificationType.News, 'Tin tức'), DriverNotificationType.OpenShareScreen, 'Trang mời bạn bè'), DriverNotificationType.OpenApp, 'Trang chủ app driver'), DriverNotificationType.OpenNews, 'Trang tin tức');