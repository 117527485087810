"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.idealDrawHistoryApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var idealDrawHistoryApi = exports.idealDrawHistoryApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/idealDrawHistory',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/idealDrawHistory',
      data: data,
      method: 'post'
    });
  },
  multiple: function multiple(data) {
    return (0, _request.default)({
      url: '/idealDrawHistory/multiple',
      data: data,
      method: 'post'
    });
  },
  uploadVideo: function uploadVideo(data, id) {
    return (0, _request.default)({
      url: "/idealDrawHistory/".concat(id, "/video"),
      data: data,
      method: 'patch'
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: '/idealDrawHistory/' + id,
      method: 'get'
    });
  },
  updateInfo: function updateInfo(id, data) {
    return (0, _request.default)({
      url: '/idealDrawHistory/' + id,
      data: data,
      method: 'patch'
    });
  },
  findIndex: function findIndex(params) {
    return (0, _request.default)({
      url: '/idealDrawHistory/no',
      params: params
    });
  }
};