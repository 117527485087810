"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.VehicleType = void 0;
var _SingleImageS = _interopRequireDefault(require("@/components/Upload/SingleImageS3.vue"));
var _vehicle = require("@/types/vehicle");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var VehicleType = exports.VehicleType = {
  CAR: "Xe ô tô",
  // Xe hoi
  BIKE: "Xe máy",
  // Gan may
  TAXI: "Taxi",
  // Taxi
  DELIVERY: "Chuyển phát",
  // Chuyen phat
  TRAM: "Xe điện",
  // Xe dien
  TRICYCLES: "Ba gác",
  // Ba gac
  DELIVERY_FOOD: "Giao đồ ăn",
  MALL: "Mall đi chợ"
};
var _default = exports.default = {
  props: ["formData", "token", "baseUrl", "baseUrlMedia"],
  components: {
    SingleImageS3: _SingleImageS.default
  },
  data: function data() {
    return {
      VehicleType: VehicleType,
      configSystem: process.env.VUE_APP_CONFIG_SYSTEM,
      rules: {
        name: {
          required: true,
          trigger: "submit",
          message: "Trường bắt buộc"
        },
        type: {
          required: true,
          trigger: "submit",
          message: "Trường bắt buộc"
        },
        image: {
          required: true,
          trigger: "submit",
          message: "Trường bắt buộc"
        },
        step: {
          required: true,
          trigger: "submit",
          message: "Trường bắt buộc"
        },
        wheelType: {
          required: true,
          trigger: "submit",
          message: "Trường bắt buộc"
        }
      },
      WheelTypeTrans: _vehicle.WheelTypeTrans
    };
  },
  methods: {
    handleUploadSuccess: function handleUploadSuccess(res) {
      // this.$set(this.formData, "image", res.data.path);
      this.$set(this.formData, "image", res);
      this.$emit("handleUploadSuccess");
    },
    beforeUpload: function beforeUpload() {
      this.$emit("beforeUpload");
    }
  }
};