"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.trackingDebtApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var trackingDebtApi = exports.trackingDebtApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/trackingCreditDebtCompany',
      params: params
    });
  }
};