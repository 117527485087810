"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _enum = require("@/utils/enum");
var _Withdraw = _interopRequireDefault(require("./Withdraw"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Withdraw: _Withdraw.default
  },
  data: function data() {
    return {
      tabName: "main",
      BalanceType: _enum.BalanceType
    };
  },
  methods: {}
};