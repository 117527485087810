"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transferBalancePromotionApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var transferBalancePromotionApi = exports.transferBalancePromotionApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/transferBalancePromotion',
      params: params
    });
  },
  complete: function complete(id) {
    return (0, _request.default)({
      url: "/transferBalancePromotion/".concat(id, "/complete"),
      method: 'post'
    });
  },
  cancel: function cancel(id) {
    return (0, _request.default)({
      url: "/transferBalancePromotion/".concat(id, "/cancel"),
      method: 'post'
    });
  }
};