import { render, staticRenderFns } from "./SponsorModal.vue?vue&type=template&id=365ee0e6"
import script from "./SponsorModal.vue?vue&type=script&lang=ts"
export * from "./SponsorModal.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/mac/Documents/project/028/028admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('365ee0e6')) {
      api.createRecord('365ee0e6', component.options)
    } else {
      api.reload('365ee0e6', component.options)
    }
    module.hot.accept("./SponsorModal.vue?vue&type=template&id=365ee0e6", function () {
      api.rerender('365ee0e6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/sponsor/components/SponsorModal.vue"
export default component.exports