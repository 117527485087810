"use strict";

var _interopRequireWildcard = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _vueCountTo = _interopRequireDefault(require("vue-count-to"));
var dashboard = _interopRequireWildcard(require("@/api/dashboard"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    CountTo: _vueCountTo.default
  },
  data: function data() {
    return {
      datePicker: [],
      dataSource: {}
    };
  },
  props: ["listQuery"],
  watch: {
    listQuery: {
      handler: function handler(val) {
        this.getData();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getData: function getData() {
      var _this = this;
      dashboard.getDashboard(this.listQuery).then(function (res) {
        _this.dataSource = res.data;
      });
    },
    queryDate: function queryDate(e) {
      if (e) {
        this.listQuery.fromAt = (0, _moment.default)(e[0]).startOf("day").unix();
        this.listQuery.toAt = (0, _moment.default)(e[1]).endOf("day").unix();
      } else {
        this.listQuery.fromAt = undefined;
        this.listQuery.toAt = undefined;
      }
      this.getData();
    }
  },
  created: function created() {
    // this.getData();
  }
};