"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MallOrderStatusTrans = exports.MallOrderStatus = exports.MallOrderProductTypeTrans = exports.MallOrderProductType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var MallOrderStatus;
(function (MallOrderStatus) {
  MallOrderStatus["Pending"] = "PENDING";
  MallOrderStatus["CustomerCancel"] = "CUSTOMER_CANCEL";
  MallOrderStatus["MallCancel"] = "MALL_CANCEL";
  MallOrderStatus["Delivering"] = "DELIVERING";
  MallOrderStatus["Packing"] = "PACKING";
  MallOrderStatus["Complete"] = "COMPLETE";
  MallOrderStatus["Refund"] = "REFUND";
  MallOrderStatus["Confirm"] = "CONFIRM";
})(MallOrderStatus || (exports.MallOrderStatus = MallOrderStatus = {}));
var MallOrderProductType;
(function (MallOrderProductType) {
  MallOrderProductType["MallProduct"] = "MALL_PRODUCT";
  MallOrderProductType["EVoucher"] = "E_VOUCHER";
})(MallOrderProductType || (exports.MallOrderProductType = MallOrderProductType = {}));
var MallOrderProductTypeTrans = exports.MallOrderProductTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, MallOrderProductType.MallProduct, 'Sản phẩm mall'), MallOrderProductType.EVoucher, 'E-Voucher');
var MallOrderStatusTrans = exports.MallOrderStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, MallOrderStatus.Pending, 'Chờ shop xác nhận'), MallOrderStatus.CustomerCancel, 'Khách hủy'), MallOrderStatus.MallCancel, 'Shop hủy'), MallOrderStatus.Delivering, 'Đang giao'), MallOrderStatus.Packing, 'Chờ lấy hàng'), MallOrderStatus.Complete, 'Hoàn tất'), MallOrderStatus.Refund, 'Hoàn trả'), MallOrderStatus.Confirm, 'Shop xác nhận');