"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.idealProductApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var idealProductApi = exports.idealProductApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/idealProduct',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/idealProduct/',
      data: data,
      method: 'post'
    });
  },
  visible: function visible(id, data) {
    return (0, _request.default)({
      url: "/idealProduct/".concat(id, "/visible"),
      data: data,
      method: 'patch'
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/idealProduct/".concat(id),
      data: data,
      method: 'patch'
    });
  },
  publish: function publish(id, data) {
    return (0, _request.default)({
      url: "/idealProduct/".concat(id, "/publish"),
      data: data,
      method: 'patch'
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/idealProduct/".concat(id),
      method: 'delete'
    });
  }
};