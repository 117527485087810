"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SponsorStatus = exports.SponsorGiftSubmitStatus = exports.SponsorGiftObjectTrans = exports.SponsorGiftObject = exports.SponsorGiftFamilyTrans = exports.SponsorGiftFamily = exports.SponsorGiftAchievedTrans = exports.SponsorGiftAchieved = exports.GenderTypeTrans = exports.GenderType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var SponsorStatus;
(function (SponsorStatus) {
  SponsorStatus["Pending"] = "PENDING";
  SponsorStatus["Approve"] = "APPROVE";
  SponsorStatus["Reject"] = "REJECT";
})(SponsorStatus || (exports.SponsorStatus = SponsorStatus = {}));
var GenderType;
(function (GenderType) {
  GenderType["Male"] = "MALE";
  GenderType["Female"] = "FEMALE";
})(GenderType || (exports.GenderType = GenderType = {}));
var GenderTypeTrans = exports.GenderTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, GenderType.Male, 'Nam'), GenderType.Female, 'Nữ');
var SponsorGiftSubmitStatus;
(function (SponsorGiftSubmitStatus) {
  SponsorGiftSubmitStatus["Pending"] = "PENDING";
  SponsorGiftSubmitStatus["Approve"] = "APPROVE";
  SponsorGiftSubmitStatus["Complete"] = "COMPLETE";
  SponsorGiftSubmitStatus["Reject"] = "REJECT";
})(SponsorGiftSubmitStatus || (exports.SponsorGiftSubmitStatus = SponsorGiftSubmitStatus = {}));
var SponsorGiftObject;
(function (SponsorGiftObject) {
  SponsorGiftObject["HocSinh"] = "HOC_SINH";
  SponsorGiftObject["SinhVien"] = "SINH_VIEN";
  SponsorGiftObject["CBNV"] = "CBNV";
  SponsorGiftObject["GV"] = "GV";
  SponsorGiftObject["NLD"] = "NLD";
})(SponsorGiftObject || (exports.SponsorGiftObject = SponsorGiftObject = {}));
var SponsorGiftObjectTrans = exports.SponsorGiftObjectTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, SponsorGiftObject.HocSinh, 'Học sinh'), SponsorGiftObject.SinhVien, 'Sinh viên'), SponsorGiftObject.CBNV, 'Cán bộ, nhân viên'), SponsorGiftObject.GV, 'Giáo viên'), SponsorGiftObject.NLD, 'Người lao động');
var SponsorGiftAchieved;
(function (SponsorGiftAchieved) {
  SponsorGiftAchieved["Gioi"] = "GIOI";
  SponsorGiftAchieved["Kha"] = "KHA";
  SponsorGiftAchieved["TB"] = "TB";
  SponsorGiftAchieved["Kem"] = "KEM";
})(SponsorGiftAchieved || (exports.SponsorGiftAchieved = SponsorGiftAchieved = {}));
var SponsorGiftAchievedTrans = exports.SponsorGiftAchievedTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, SponsorGiftAchieved.Gioi, 'Giỏi'), SponsorGiftAchieved.Kha, 'Khá'), SponsorGiftAchieved.TB, 'Trung bình'), SponsorGiftAchieved.Kem, 'Kém');
var SponsorGiftFamily;
(function (SponsorGiftFamily) {
  SponsorGiftFamily["CoCongDatNuoc"] = "CO_CONG_DAT_NUOC";
  SponsorGiftFamily["CanBo"] = "CAN_BO";
  SponsorGiftFamily["DoanhNghiep"] = "DOANH_NGHIEP";
  SponsorGiftFamily["TieuThuong"] = "TIEU_THUONG";
  SponsorGiftFamily["LaoDong"] = "LAO_DONG";
  SponsorGiftFamily["VungSau"] = "VUNG_SAU";
})(SponsorGiftFamily || (exports.SponsorGiftFamily = SponsorGiftFamily = {}));
var SponsorGiftFamilyTrans = exports.SponsorGiftFamilyTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, SponsorGiftFamily.CoCongDatNuoc, 'Có công với đất nước'), SponsorGiftFamily.CanBo, 'CB Công - Viên chức'), SponsorGiftFamily.DoanhNghiep, 'Doanh nghiệp'), SponsorGiftFamily.TieuThuong, 'Tiểu thương'), SponsorGiftFamily.LaoDong, 'Lao động'), SponsorGiftFamily.VungSau, 'Khó khăn / Vùng sâu Vùng xa / Biển hải đảo');