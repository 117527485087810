"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.json.stringify.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/web.url.js");
require("core-js/modules/web.url.to-json.js");
require("core-js/modules/web.url-search-params.js");
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _UploadExcel = _interopRequireDefault(require("@/components/UploadExcel"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    UploadExcel: _UploadExcel.default
  },
  data: function data() {
    return {
      column: "",
      data: []
    };
  },
  methods: {
    handleGetData: function handleGetData() {
      var _this = this;
      var res = {};
      this.data.forEach(function (element) {
        Object.keys(element).forEach(function (key) {
          if (key == _this.column) {
            res[element["Key"]] = element[key];
          }
        });
      });
      console.log("result ne", JSON.stringify(res));
      var blob = new Blob([JSON.stringify(res, null, 2)], {
        type: "application/json"
      });
      var url = URL.createObjectURL(blob);
      var link = document.createElement("a");
      link.href = url;
      link.download = "lang.json";
      link.dispatchEvent(new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window
      }));
    },
    onSuccess: function onSuccess(_ref) {
      var results = _ref.results;
      this.data = results;
    }
  }
};