"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
require("core-js/modules/es.object.to-string.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
var _cardOrder = require("@/types/cardOrder");
_vue.default.use(_vueRouter.default);

/* Layout */

/* Router Modules */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: "/redirect",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "/redirect/:path*",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/redirect/index"));
      });
    }
  }]
}, {
  path: "/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/index"));
    });
  },
  hidden: true
}, {
  path: "/auth-redirect",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/auth-redirect"));
    });
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/404"));
    });
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/401"));
    });
  },
  hidden: true
}, {
  path: "/admin/driver/income/chart",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/charts/driver"));
    });
  },
  hidden: true,
  meta: {
    noCache: true
  }
}, {
  path: "/",
  redirect: "/dashboard",
  hidden: true
}, {
  path: "/translate",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/translate"));
    });
  },
  hidden: true,
  meta: {
    title: "Translate excel to json"
  }
}, {
  path: "/translate/json",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/translate-json"));
    });
  },
  hidden: true,
  meta: {
    title: "Translate json to excel"
  }
}, {
  path: "/dashboard",
  component: _layout.default,
  redirect: "/dashboard/index",
  permission: true,
  meta: {
    title: "Dashboard",
    icon: "dashboard",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/dashboard/index"));
      });
    },
    name: "dashboard-index",
    meta: {
      title: "Chung",
      permission: true
    }
  }]
}, {
  path: "/media",
  component: _layout.default,
  redirect: "/media",
  meta: {
    title: "Media",
    icon: "photo-video-solid",
    permission: true
  },
  permission: true,
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/media"));
      });
    },
    name: "media",
    meta: {
      permission: true,
      title: "Media"
    }
  }]
}, {
  path: "/order",
  component: _layout.default,
  redirect: "/order/index",
  meta: {
    title: "Đơn hàng",
    icon: "car",
    permission: true
  },
  permission: true,
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/index"));
      });
    },
    name: "order-index",
    meta: {
      title: "Đơn gọi xe",
      permission: true
    }
  }, {
    path: "export-excel",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order"));
      });
    },
    name: "order-export-excel",
    hidden: true,
    meta: {
      title: "Xuất excel đơn hàng",
      permission: true
    }
  }]
}, {
  path: "/order-delivery",
  component: _layout.default,
  redirect: "/order-delivery/index",
  meta: {
    title: "Đơn giao hàng",
    icon: "delivery",
    permission: true
  },
  hidden: false,
  permission: true,
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order-delivery/index"));
      });
    },
    name: "order-delivery-index",
    meta: {
      title: "Đơn giao hàng",
      permission: true
    }
  }, {
    path: "create-batch",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order-delivery"));
      });
    },
    name: "order-delivery-create-batch",
    hidden: true,
    meta: {
      title: "Tạo đơn hàng (Excel)",
      permission: true
    }
  }, {
    path: "export-excel",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order-delivery"));
      });
    },
    name: "order-delivery-export-excel",
    hidden: true,
    meta: {
      title: "Xuất excel đơn giao hàng",
      permission: true
    }
  }, {
    path: "complete-order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order-delivery"));
      });
    },
    name: "order-delivery-complete",
    hidden: true,
    meta: {
      title: "Hoàn tất đơn giao hàng",
      permission: true
    }
  }]
}, {
  path: "/order-food",
  component: _layout.default,
  redirect: "/order-food/index",
  meta: {
    title: "Đơn giao thức ăn",
    icon: "delivery",
    permission: true
  },
  permission: true,
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order-food/index"));
      });
    },
    name: "order-food-index",
    meta: {
      title: "Đơn giao thức ăn",
      permission: true
    }
  }, {
    path: "export-excel",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order-food"));
      });
    },
    name: "order-food-export-excel",
    hidden: true,
    meta: {
      title: "Xuất excel đơn giao thức ăn",
      permission: true
    }
  }]
}, {
  path: "/order-mall",
  component: _layout.default,
  redirect: "/order-mall/index",
  meta: {
    title: "Đơn FclassMall",
    icon: "delivery",
    permission: true
  },
  permission: true,
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order-mall/index"));
      });
    },
    name: "order-mall-index",
    meta: {
      title: "Đơn FclassMall",
      permission: true
    }
  }, {
    path: "export-excel",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order-mall"));
      });
    },
    name: "order-mall-export-excel",
    hidden: true,
    meta: {
      title: "Xuất excel đơn FclassMall",
      permission: true
    }
  }]
}, {
  path: "/customer",
  component: _layout.default,
  redirect: "/customer/index",
  meta: {
    title: "Người Dùng",
    icon: "peoples",
    permission: true
  },
  permission: true,
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/customer/index"));
      });
    },
    name: "customer-index",
    meta: {
      title: "Danh sách người dùng",
      permission: true
    }
  }, {
    path: "deposit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/deposit-customer/index"));
      });
    },
    name: "customer-deposit",
    meta: {
      title: "Nạp tiền",
      permission: true
    }
  }, {
    path: "withdraw",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/withdraw-customer/index"));
      });
    },
    name: "customer-withdraw",
    meta: {
      title: "Rút tiền",
      permission: true
    }
  }, {
    path: "create",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/customer/index"));
      });
    },
    name: "customer-create",
    hidden: true,
    meta: {
      title: "Thêm mới người dùng",
      permission: true
    }
  }, {
    path: "customer-ref",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/customer/index"));
      });
    },
    name: "customer-ref",
    hidden: true,
    meta: {
      title: "Cập nhật mã giới thiệu người dùng",
      permission: true
    }
  }, {
    path: "customer-phone",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/customer/index"));
      });
    },
    name: "customer-phone",
    hidden: true,
    meta: {
      title: "Cập nhật số điện thoại người dùng",
      permission: true
    }
  }, {
    path: "update-address",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/customer/index"));
      });
    },
    name: "customer-update-address",
    hidden: true,
    meta: {
      title: "Cập nhật địa chỉ người dùng",
      permission: true
    }
  }, {
    path: "export-customer-excel",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/customer"));
      });
    },
    name: "customer-export-excel",
    hidden: true,
    meta: {
      title: "Xuất excel người dùng",
      permission: true
    }
  }, {
    path: "export-customer-excel-area",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/customer"));
      });
    },
    name: "customer-export-excel-area",
    hidden: true,
    meta: {
      title: "Xuất excel theo khu vực",
      permission: true
    }
  }, {
    path: "transfer-balance",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/customer-transfer-balance/index"));
      });
    },
    name: "customer-transfer-balance",
    meta: {
      title: "Chuyển tiền",
      permission: true
    }
  }]
}, {
  path: "/mart",
  component: _layout.default,
  meta: {
    title: "Cửa hàng F&B",
    icon: "shopping-fill",
    permission: true
  },
  permission: true,
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mart"));
      });
    },
    name: "mart-index",
    meta: {
      title: "Cửa hàng F&B",
      permission: true
    }
  }, {
    path: "detail",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mart/detail"));
      });
    },
    name: "mart-detail-index",
    hidden: true,
    meta: {
      title: "Cửa hàng F&B",
      permission: false
    }
  }, {
    path: "/update-store-commission",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mart"));
      });
    },
    name: "mart-update-store-commission",
    hidden: true,
    meta: {
      title: "Chỉnh sửa cấu hình hoa hồng",
      permission: true
    }
  }, {
    path: "/update-store-threshold-balance",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mart"));
      });
    },
    name: "mart-update-store-threshold-balance",
    hidden: true,
    meta: {
      title: "Chỉnh sửa cấu hình tín dụng",
      permission: true
    }
  }, {
    path: "branch",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/branch-store"));
      });
    },
    name: "store-branch",
    meta: {
      title: "Chuỗi cửa hàng",
      permission: true
    }
  }, {
    path: "bad-debt",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mart-bad-debt"));
      });
    },
    name: "mart-bad-debt",
    meta: {
      title: "Nợ xấu",
      permission: true
    }
  }, {
    path: "/delete",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mart"));
      });
    },
    name: "mart-delete",
    hidden: true,
    meta: {
      title: "Xóa Cửa hàng",
      permission: true
    }
  }, {
    path: "/block",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mart"));
      });
    },
    name: "mart-block",
    hidden: true,
    meta: {
      title: "Khóa Cửa hàng",
      permission: true
    }
  }, {
    path: "partner-config",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mart"));
      });
    },
    name: "mart-partner-config",
    hidden: true,
    meta: {
      title: "Cấu hình đối tác",
      permission: true
    }
  }, {
    path: "export-excel",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mart"));
      });
    },
    name: "mart-export-excel",
    hidden: true,
    meta: {
      title: "Xuất excel cửa hàng",
      permission: true
    }
  }, {
    path: "create",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/customer/index"));
      });
    },
    name: "mart-create",
    hidden: true,
    meta: {
      title: "Thêm mới cửa hàng",
      permission: true
    }
  }, {
    path: "/unblock",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mart"));
      });
    },
    name: "mart-unblock",
    hidden: true,
    meta: {
      title: "Mở khóa Cửa hàng",
      permission: true
    }
  }, {
    path: "/censor",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mart"));
      });
    },
    name: "mart-censor",
    hidden: true,
    meta: {
      title: "Duyệt Cửa hàng",
      permission: true
    }
  }, {
    path: "store-recommend",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/store-recommend"));
      });
    },
    name: "store-recommend-index",
    meta: {
      title: "Gợi ý cửa hàng",
      permission: true
    }
  },
  // {
  //   path: "food-type",
  //   component: () => import("@/views/food-type"),
  //   name: "food-type-index",
  //   meta: {
  //     title: "Loại ẩm thực",
  //     permission: true
  //   }
  // },
  {
    path: "banner",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/store-banner"));
      });
    },
    name: "store-banner",
    meta: {
      title: "Banner F&B",
      permission: true
    }
  }, {
    path: "popup",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/store-popup"));
      });
    },
    name: "store-popup",
    meta: {
      title: "Popup F&B",
      permission: true
    }
  }, {
    path: "deposit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/deposit-store/index"));
      });
    },
    name: "store-deposit",
    meta: {
      title: "Nạp tiền",
      permission: true
    }
  }, {
    path: "withdraw",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/withdraw-store/index"));
      });
    },
    name: "store-withdraw",
    meta: {
      title: "Rút tiền",
      permission: true
    }
  }, {
    path: "news",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/store-news/index"));
      });
    },
    name: "store-news",
    meta: {
      title: "Tin tức",
      permission: true
    }
  }, {
    path: "news-export-excel",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/store-news/index"));
      });
    },
    name: "store-news-export-excel",
    hidden: true,
    meta: {
      title: "Xuất excel tin tức",
      permission: true
    }
  }, {
    path: "notification",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/merchant-notification/index"));
      });
    },
    name: "store-notification",
    meta: {
      title: "Tin nhắn gửi",
      permission: true
    }
  }, {
    path: "export-notification",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/merchant-notification/index"));
      });
    },
    name: "export-store-notification",
    hidden: true,
    meta: {
      title: "Xuất excel tin nhắn gửi",
      permission: true
    }
  }, {
    path: "city",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/address-store/City"));
      });
    },
    name: "mart-address-city",
    meta: {
      title: "Tỉnh thành",
      permission: true
    }
  }]
}, {
  path: "/agent",
  component: _layout.default,
  meta: {
    title: "Fclass Agency",
    icon: "shopping-fill",
    permission: true
  },
  permission: true,
  children: [{
    path: "agent-dashboard-index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/agent-dashboard/index.vue"));
      });
    },
    name: "agent-dashboard-index",
    meta: {
      title: "Thống kê",
      permission: true
    }
  }, {
    path: "agent",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/agent"));
      });
    },
    name: "agent-index",
    meta: {
      title: "Danh sách Agency",
      permission: true
    }
  }, {
    path: "product",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/product"));
      });
    },
    name: "product-index",
    meta: {
      title: "Sản phẩm",
      permission: true
    }
  }, {
    path: "product-category",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/product-category"));
      });
    },
    name: "product-category-index",
    meta: {
      title: "Danh mục sản phẩm",
      permission: true
    }
  }, {
    path: "agent-order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/agent-order"));
      });
    },
    name: "agent-order",
    meta: {
      title: "Đơn hàng",
      permission: true
    }
  }, {
    path: "agent-banner",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/agent-banner"));
      });
    },
    name: "agentBanner-index",
    meta: {
      title: "Agency Banner",
      permission: true
    }
  }, {
    path: "agent-kyc",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/agent-kyc"));
      });
    },
    name: "agent-kyc",
    meta: {
      title: "Agency KYC",
      permission: true
    }
  }, {
    path: "agent-rank",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/agent-rank"));
      });
    },
    name: "agent-rank",
    meta: {
      title: "Thứ hạng Agency",
      permission: true
    }
  },
  // {
  //   path: "agent-order",
  //   component: () => import("@/views/agent-order"),
  //   name: "agent-order",
  //   meta: {
  //     title: "Đơn hàng",
  //     permission: true,
  //   },
  // },
  {
    path: "agent-content-define",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/agent-content-define"));
      });
    },
    name: "agent-content-define",
    meta: {
      title: "Chính sách",
      permission: true
    }
  }, {
    path: "provider",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/provider"));
      });
    },
    name: "provider-index",
    meta: {
      title: "Nhà cung cấp",
      permission: true
    }
  }, {
    path: "provider-withdraw",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/provider-withdraw"));
      });
    },
    name: "provider-withdraw-index",
    meta: {
      title: "Rút tiền NCC",
      permission: true
    }
  }, {
    path: "agent-withdraw",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/agent-withdraw/AgentWithdraw.vue"));
      });
    },
    name: "agent-withdraw-index",
    meta: {
      title: "Rút tiền Agency",
      permission: true
    }
  }, {
    path: "depot",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/depot"));
      });
    },
    name: "depot",
    meta: {
      title: "Kho hàng",
      permission: true
    }
  }, {
    path: "agent-brand",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/agent-brand"));
      });
    },
    name: "agent-brand",
    meta: {
      title: "Thương hiệu",
      permission: true
    }
  },
  // {
  //   path: "agent-promotion",
  //   component: () => import("@/views/agent-promotion"),
  //   name: "agent-promotion-index",
  //   meta: {
  //     title: "Khuyến mãi",
  //     permission: true,
  //   },
  // },
  // {
  //   path: "agent-training-schedule",
  //   component: () => import("@/views/agent-training-schedule"),
  //   name: "agent-training-schedule-index",
  //   meta: {
  //     title: "Lịch đào tạo",
  //     permission: true,
  //   },
  // },
  // {
  //   path: "agent-promotion-campaign",
  //   component: () => import("@/views/agent-promotion-campaign"),
  //   name: "agent-promotion-campaign-index",
  //   meta: {
  //     title: "Chương trình ưu đãi",
  //     permission: true,
  //   },
  // },
  {
    path: "agent-config",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/agent-config"));
      });
    },
    name: "agent-config-index",
    meta: {
      title: "Cấu hình",
      permission: true
    }
  }]
}, {
  path: "/partner-card",
  component: _layout.default,
  redirect: "/partner-card/index",
  permission: true,
  meta: {
    title: "Thẻ cào",
    icon: "bank-fill",
    permission: true
  },
  children: [{
    path: "card-order-dashboard-index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/card-order-dashboard/index.vue"));
      });
    },
    name: "card-order-dashboard-index",
    meta: {
      title: "Thống kê",
      permission: true
    }
  }, {
    path: "card-product",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/card-product/index"));
      });
    },
    name: "card-product-index",
    meta: {
      title: "Loại sản phẩm",
      permission: true
    }
  }, {
    path: "card-order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/card-order/index"));
      });
    },
    name: "card-order-index",
    meta: {
      title: "Lịch sử mua thẻ",
      permission: true
    },
    props: {
      type: _cardOrder.CardOrderType.Card
    }
  }, {
    path: "top-up",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/card-order/index"));
      });
    },
    name: "top-up-transaction-index",
    meta: {
      title: "Lịch sử topup",
      permission: true
    },
    props: {
      type: _cardOrder.CardOrderType.Topup
    }
  }, {
    path: "bill",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/card-order/index"));
      });
    },
    name: "bill-transaction-index",
    meta: {
      title: "Đơn thanh toán bill",
      permission: true
    },
    props: {
      type: _cardOrder.CardOrderType.Bill
    }
  }
  // {
  //   path: "top-up",
  //   component: () => import("@/views/top-up-transaction/index"),
  //   name: "top-up-transaction-index",
  //   meta: {
  //     title: "Lịch sử topup",
  //     permission: true,
  //   },
  // },
  ]
}, {
  path: "/fclass-draw",
  component: _layout.default,
  redirect: "/fclass-draw/index",
  permission: true,
  meta: {
    title: "Bốc thăm",
    icon: "ticket",
    permission: true
  },
  children: [{
    path: "products",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "ideal-product",
    meta: {
      title: "Campaigns",
      permission: true
    }
  }, {
    path: "add-product",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "add-product",
    hidden: true,
    meta: {
      title: "Thêm sản phẩm",
      permission: true
    }
  }, {
    path: "delete-product",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "delete-product",
    hidden: true,
    meta: {
      title: "Xoá sản phẩm",
      permission: true
    }
  }, {
    path: "copy-product",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "copy-product",
    hidden: true,
    meta: {
      title: "Sao chép sản phẩm",
      permission: true
    }
  }, {
    path: "update-product",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "update-product",
    hidden: true,
    meta: {
      title: "Sửa sản phẩm",
      permission: true
    }
  }, {
    path: "coupon",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealCouponPage"));
      });
    },
    name: "Quản lý coupon",
    meta: {
      title: "Quản lý coupon",
      permission: true
    }
  }, {
    path: "add-coupon",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "add-coupon",
    hidden: true,
    meta: {
      title: "Thêm coupon",
      permission: true
    }
  }, {
    path: "update-coupon",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "update-coupon",
    hidden: true,
    meta: {
      title: "Sửa coupon",
      permission: true
    }
  }, {
    path: "delete-coupon",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "delete-coupon",
    hidden: true,
    meta: {
      title: "Xoá coupon",
      permission: true
    }
  }, {
    path: "order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealOrderPage"));
      });
    },
    name: "Đơn hàng",
    meta: {
      title: "Đơn hàng",
      permission: true
    }
  }, {
    path: "confirm-order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "confirm-order",
    hidden: true,
    meta: {
      title: "Yêu cầu giao hàng",
      permission: true
    }
  }, {
    path: "code",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealCodePage"));
      });
    },
    name: "DS Thăm",
    meta: {
      title: "DS Thăm",
      permission: true
    }
  }, {
    path: "draw-history",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealDrawHistory"));
      });
    },
    name: "Kết quả trúng thưởng",
    meta: {
      title: "Kết quả trúng thưởng",
      permission: true
    }
  }, {
    path: "confirm-reward",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "confirm-reward",
    hidden: true,
    meta: {
      title: "Xác nhận đã trao thưởng",
      permission: true
    }
  }, {
    path: "strategy",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealStrategyPage"));
      });
    },
    name: "Chiến dịch quảng cáo",
    meta: {
      title: "Chiến dịch quảng cáo",
      permission: true
    }
  }, {
    path: "idea-strategy-add",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "idea-strategy-add",
    hidden: true,
    meta: {
      title: "Thêm chiến dịch quảng cáo",
      permission: true
    }
  }, {
    path: "idea-strategy-delete",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "idea-strategy-delete",
    hidden: true,
    meta: {
      title: "Xoá chiến dịch quảng cáo",
      permission: true
    }
  }, {
    path: "idea-strategy-update",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "idea-strategy-update",
    hidden: true,
    meta: {
      title: "Chỉnh sửa chiến dịch quảng cáo",
      permission: true
    }
  }, {
    path: "banner",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealBanner"));
      });
    },
    name: "Banner",
    meta: {
      title: "Banner",
      permission: true
    }
  }, {
    path: "add-banner",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "add-banner",
    hidden: true,
    meta: {
      title: "Thêm banner",
      permission: true
    }
  }, {
    path: "delete-banner",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "delete-banner",
    hidden: true,
    meta: {
      title: "Xoá banner",
      permission: true
    }
  }, {
    path: "update-banner",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "update-banner",
    hidden: true,
    meta: {
      title: "Chỉnh sửa banner",
      permission: true
    }
  }, {
    path: "notification",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealNoitification"));
      });
    },
    name: "Thông báo",
    meta: {
      title: "Thông báo",
      permission: true
    }
  }, {
    path: "add-noti",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "add-noti",
    hidden: true,
    meta: {
      title: "Thêm thông báo",
      permission: true
    }
  }, {
    path: "update-noti",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "update-noti",
    hidden: true,
    meta: {
      title: "Chỉnh sửa thông báo",
      permission: true
    }
  }, {
    path: "delete-noti",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "delete-noti",
    hidden: true,
    meta: {
      title: "Xoá thông báo",
      permission: true
    }
  }, {
    path: "copy-noti",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "copy-noti",
    hidden: true,
    meta: {
      title: "Sao chép thông báo",
      permission: true
    }
  }, {
    path: "send-noti",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "send-noti",
    hidden: true,
    meta: {
      title: "Gửi thông báo",
      permission: true
    }
  }, {
    path: "address",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealAddress"));
      });
    },
    name: "Quản lý kho",
    meta: {
      title: "Quản lý kho",
      permission: true
    }
  }, {
    path: "add-warehouse",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "add-warehouse",
    hidden: true,
    meta: {
      title: "Thêm kho",
      permission: true
    }
  }, {
    path: "update-warehouse",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "update-warehouse",
    hidden: true,
    meta: {
      title: "Cập nhật thông tin kho",
      permission: true
    }
  }, {
    path: "delete-warehouse",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/IdealProductPage"));
      });
    },
    name: "delete-warehouse",
    hidden: true,
    meta: {
      title: "Xoá kho",
      permission: true
    }
  }]
}, {
  path: "/boc-tham-ngau-nhien",
  // component: () => import("@/views/IdealDrawHistory/IdealDrawPage"),
  redirect: "/boc-tham-ngau-nhien/index",
  component: _layout.default,
  hidden: true,
  meta: {
    title: "Trang bốc thăm",
    icon: "bank-fill",
    permission: true
  },
  permission: true,
  children: [{
    path: "index",
    name: "boc-tham-ngau-nhien",
    meta: {
      title: "Trang bốc thăm",
      permission: true
    }
  }]
},
//Fclass Edu
{
  path: "/fclass-edu",
  component: _layout.default,
  redirect: "/fclass-edu/index",
  meta: {
    title: "Fclass Edu",
    icon: "bank-fill",
    permission: true
  },
  permission: true,
  children: [{
    path: "/fclass-edu/index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/edu-host/index"));
      });
    },
    name: "fclass-edu-index",
    meta: {
      title: "Tài khoản giảng viên",
      permission: true
    }
  }, {
    path: "/fclass-edu/category",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/edu-category/index.vue"));
      });
    },
    name: "edu-category",
    meta: {
      title: "Danh mục",
      permission: true
    }
  }, {
    path: "/fclass-edu/course",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/edu-course/index.vue"));
      });
    },
    name: "edu-course",
    meta: {
      title: "Khóa học",
      permission: true
    }
  }, {
    path: "/fclass-edu/lecture",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/edu-lecture/index.vue"));
      });
    },
    name: "edu-lecture",
    meta: {
      title: "Video khóa học",
      permission: true
    }
  }, {
    path: "/fclass-edu/order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/edu-order/index.vue"));
      });
    },
    name: "edu-order",
    meta: {
      title: "Đơn hàng",
      permission: true
    }
  }, {
    path: "/fclass-edu/banner",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/edu-banner/index.vue"));
      });
    },
    name: "edu-banner",
    meta: {
      title: "Banner",
      permission: true
    }
  }, {
    path: "/fclass-edu/tag",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/edu-tag/index.vue"));
      });
    },
    name: "edu-tag",
    meta: {
      title: "Thẻ",
      permission: true
    }
  }, {
    path: "/fclass-edu/language",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/edu-language/index.vue"));
      });
    },
    name: "edu-language",
    meta: {
      title: "Ngôn ngữ",
      permission: true
    }
  }, {
    path: "/fclass-edu/transaction",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/edu-transaction/index.vue"));
      });
    },
    name: "edu-transaction",
    meta: {
      title: "Lịch sử giao dịch",
      permission: true
    }
  }, {
    path: "/fclass-edu/withdraw",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/edu-withdraw/index.vue"));
      });
    },
    name: "edu-withdraw",
    meta: {
      title: "Yêu cầu rút tiền",
      permission: true
    }
  }, {
    path: "/fclass-edu/review-config",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/edu-review-config/index.vue"));
      });
    },
    name: "edu-review-config",
    meta: {
      title: "Cấu hình đánh giá",
      permission: true
    }
  }, {
    path: "/fclass-edu/host-config",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/host-config/index.vue"));
      });
    },
    name: "host-config",
    meta: {
      title: "Cấu hình giảng viên",
      permission: true
    }
  }
  // {
  //   path: "deposit",
  //   component: () => import("@/views/deposit-customer/index"),
  //   name: "customer-deposit",
  //   meta: {
  //     title: "Nạp tiền",
  //     permission: true,
  //   },
  // },
  // {
  //   path: "create",
  //   component: () => import("@/views/customer/index"),
  //   name: "customer-create",
  //   hidden: true,
  //   meta: {
  //     title: "Thêm mới người dùng",
  //     permission: true,
  //   },
  // },
  // {
  //   path: "customer-ref",
  //   component: () => import("@/views/customer/index"),
  //   name: "customer-ref",
  //   hidden: true,
  //   meta: {
  //     title: "Cập nhật mã giới thiệu người dùng",
  //     permission: true,
  //   },
  // },
  // {
  //   path: "customer-phone",
  //   component: () => import("@/views/customer/index"),
  //   name: "customer-phone",
  //   hidden: true,
  //   meta: {
  //     title: "Cập nhật số điện thoại người dùng",
  //     permission: true,
  //   },
  // },
  // {
  //   path: "update-address",
  //   component: () => import("@/views/customer/index"),
  //   name: "customer-update-address",
  //   hidden: true,
  //   meta: {
  //     title: "Cập nhật địa chỉ người dùng",
  //     permission: true,
  //   },
  // },
  // {
  //   path: "export-customer-excel",
  //   component: () => import("@/views/customer"),
  //   name: "customer-export-excel",
  //   hidden: true,
  //   meta: {
  //     title: "Xuất excel người dùng",
  //     permission: true,
  //   },
  // },
  // {
  //   path: "export-customer-excel-area",
  //   component: () => import("@/views/customer"),
  //   name: "customer-export-excel-area",
  //   hidden: true,
  //   meta: {
  //     title: "Xuất excel theo khu vực",
  //     permission: true,
  //   },
  // },
  // {
  //   path: "transfer-balance",
  //   component: () => import("@/views/customer-transfer-balance/index"),
  //   name: "customer-transfer-balance",
  //   meta: {
  //     title: "Chuyển tiền",
  //     permission: true,
  //   },
  // },
  ]
},
// Fclass tuyển sinh
{
  path: "/fclass-admissions",
  component: _layout.default,
  redirect: "/fclass-admissions/index",
  meta: {
    title: "Fclass tuyển sinh",
    icon: "bank-fill",
    permission: true
  },
  permission: true,
  children: [{
    path: "/fclass-admissions/index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/school/index.vue"));
      });
    },
    name: "school",
    meta: {
      title: "Trường",
      permission: true
    }
  }, {
    path: "/fclass-admissions/schoolType",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/school-type/index.vue"));
      });
    },
    name: "schoolType",
    meta: {
      title: "Loại trường",
      permission: true
    }
  },
  // {
  //   path: "/fclass-admissions/schoolAddress",
  //   component: () => import("@/views/schoolAddress/index.vue"),
  //   name: "schoolAddress",
  //   meta: {
  //     title: "schooladdress",
  //     permission: true,
  //   },
  // },
  {
    path: "/fclass-admissions/major",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/school-major/index.vue"));
      });
    },
    name: "school-major",
    meta: {
      title: "Chuyên ngành",
      permission: true
    }
  }, {
    path: "/fclass-admissions/school-subject",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/school-subject/index.vue"));
      });
    },
    name: "school-subject",
    meta: {
      title: "Môn học",
      permission: true
    }
  }, {
    path: "/fclass-admissions/level",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/school-level/index.vue"));
      });
    },
    name: "school-level",
    meta: {
      title: "Bậc học",
      permission: true
    }
  }, {
    path: "/fclass-admissions/organization",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/organization/index.vue"));
      });
    },
    name: "organization",
    meta: {
      title: "Cơ quan QL",
      permission: true
    }
  }, {
    path: "region",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/region/index"));
      });
    },
    name: "region",
    meta: {
      title: "Vùng",
      permission: true
    }
  }, {
    path: "/fclass-admissions/methods",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/school-admission-method/index.vue"));
      });
    },
    name: "school-admission-method",
    meta: {
      title: "Phương thức xét tuyển",
      permission: true
    }
  }, {
    path: "/fclass-admissions/school-admission",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/school-admission/index.vue"));
      });
    },
    name: "school-admission",
    meta: {
      title: "Danh sách tuyển sinh",
      permission: true
    }
  }, {
    path: "/fclass-admissions/school-admission-submit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/school-submit/index.vue"));
      });
    },
    name: "school-admission-submit",
    meta: {
      title: "Nộp tuyển sinh",
      permission: true
    }
  }, {
    path: "/fclass-admissions/bankConfig",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/bank-config/index.vue"));
      });
    },
    name: "bankConfig",
    meta: {
      title: "Cấu hình thanh toán",
      permission: true
    }
  }, {
    path: "/fclass-admissions/banner",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/school-banner/index.vue"));
      });
    },
    name: "school-banner",
    meta: {
      title: "Banner",
      permission: true
    }
  }, {
    path: "/fclass-admissions/content",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/school-content/index.vue"));
      });
    },
    name: "school-content",
    meta: {
      title: "Nội dung",
      permission: true
    }
  }, {
    path: "/fclass-admissions/tag",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/school-tag/index.vue"));
      });
    },
    name: "school-tag",
    meta: {
      title: "Thẻ",
      permission: true
    }
  }, {
    path: "/fclass-admissions/transaction",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/school-transaction/index.vue"));
      });
    },
    name: "school-transaction",
    meta: {
      title: "Lịch sử giao dịch",
      permission: true
    }
  }, {
    path: "/fclass-admissions/withdraw",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/school-withdraw/index.vue"));
      });
    },
    name: "school-withdraw",
    meta: {
      title: "Yêu cầu rút tiền",
      permission: true
    }
  }, {
    path: "approve-school-admission",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/school-admission"));
      });
    },
    name: "approve-school-admission",
    hidden: true,
    meta: {
      title: "Duyệt tuyển sinh",
      permission: true
    }
  }, {
    path: "approve-school",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/school/index.vue"));
      });
    },
    name: "approve-school",
    hidden: true,
    meta: {
      title: "Duyệt tài khoản trường",
      permission: true
    }
  }, {
    path: "/fclass-admissions/school-config",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/school-config/index.vue"));
      });
    },
    name: "school-config",
    meta: {
      title: "Cấu hình trường",
      permission: true
    }
  }]
},
//Fclass tuyển dụng
{
  path: "/fclass-career",
  component: _layout.default,
  redirect: "/fclass-career/index",
  meta: {
    title: "Tuyển dụng",
    icon: "bank-fill",
    permission: true
  },
  permission: true,
  children: [{
    path: "/fclass-career/index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/employer/index.vue"));
      });
    },
    name: "career",
    meta: {
      title: "Nhà tuyển dụng",
      permission: true
    }
  }, {
    path: "approve-employer",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/employer/index.vue"));
      });
    },
    name: "approve-employer",
    hidden: true,
    meta: {
      title: "Duyệt nhà tuyển dụng",
      permission: true
    }
  }, {
    path: "/fclass-career/workType",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/work-type/index.vue"));
      });
    },
    name: "workType",
    meta: {
      title: "Hình thức làm việc",
      permission: true
    }
  }, {
    path: "/fclass-career/company-size",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/company-size/index.vue"));
      });
    },
    name: "company-size",
    meta: {
      title: "Quy mô công ty",
      permission: true
    }
  }, {
    path: "/fclass-career/company-type",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/company-type/index.vue"));
      });
    },
    name: "company-type",
    meta: {
      title: "Loại hình công ty",
      permission: true
    }
  }, {
    path: "/fclass-career/cv",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/cv/index.vue"));
      });
    },
    name: "cv",
    meta: {
      title: "Mẫu CV / Cover Letter",
      permission: true
    }
  }, {
    path: "/fclass-career/career-category",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/career-category/index.vue"));
      });
    },
    name: "career-category",
    meta: {
      title: "Ngành nghề",
      permission: true
    }
  }, {
    path: "/fclass-career/career",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/career/index.vue"));
      });
    },
    name: "career",
    meta: {
      title: "Tin tuyển dụng",
      permission: true
    }
  }, {
    path: "/fclass-career/job-seeker",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/career/jobSeeker.vue"));
      });
    },
    name: "job-seeker",
    meta: {
      title: "Tin người tìm việc",
      permission: true
    }
  }, {
    path: "career-banner",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/career-banner/index.vue"));
      });
    },
    name: "career-banner",
    meta: {
      title: "Banner",
      permission: true
    }
  }, {
    path: "career-degree",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/career-degree/index.vue"));
      });
    },
    name: "career-degree",
    meta: {
      title: "Trình độ",
      permission: true
    }
  }, {
    path: "career-experience",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/career-experience/index.vue"));
      });
    },
    name: "career-experience",
    meta: {
      title: "Kinh nghiệm",
      permission: true
    }
  }, {
    path: "career-level",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/career-level/index.vue"));
      });
    },
    name: "career-level",
    meta: {
      title: "Cấp bậc",
      permission: true
    }
  }, {
    path: "cv-submit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/cv-submit/index.vue"));
      });
    },
    name: "cv-submit",
    meta: {
      title: "Đơn tuyển dụng",
      permission: true
    }
  }, {
    path: "approve-carrer",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/employer/index.vue"));
      });
    },
    name: "approve-carrer",
    hidden: true,
    meta: {
      title: "Duyệt tin tuyển dụng",
      permission: true
    }
  }, {
    path: "employer-transaction",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/employer-transaction/index.vue"));
      });
    },
    name: "employer-transaction",
    meta: {
      title: "Lịch sử giao dịch",
      permission: true
    }
  }, {
    path: "deposit-employer",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/deposit-employer/index.vue"));
      });
    },
    name: "deposit-employer",
    meta: {
      title: "Nạp tiền",
      permission: true
    }
  }]
},
//Fclass học bổng
{
  path: "/fclass-scholarship",
  component: _layout.default,
  redirect: "/fclass-scholarship/index",
  meta: {
    title: "Học bổng",
    icon: "bank-fill",
    permission: true
  },
  permission: true,
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/scholarship/index.vue"));
      });
    },
    name: "scholarship",
    meta: {
      title: "Học bổng",
      permission: true
    }
  }, {
    path: "scholarship-classify",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/scholarship-classify/index.vue"));
      });
    },
    name: "scholarship-classify",
    meta: {
      title: "Loại học bổng",
      permission: true
    }
  },
  // {
  //   path: "nation",
  //   component: () => import("@/views/nation/index.vue"),
  //   name: "nation",
  //   meta: {
  //     title: "Quốc gia",
  //     permission: true,
  //   },
  // },
  {
    path: "scholarship-banner",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/scholarship-banner/index.vue"));
      });
    },
    name: "scholarship-banner",
    meta: {
      title: "Banner",
      permission: true
    }
  }]
},
// Fclass khuyến học
{
  path: "/fclass-encourage-study",
  component: _layout.default,
  redirect: "/fclass-encourage-study/index",
  meta: {
    title: "Khuyến học/Thiện nguyện",
    icon: "bank-fill",
    permission: true
  },
  permission: true,
  children: [
  // {
  //   path: "encourage-study",
  //   component: () => import("@/views/scholarship/study.vue"),
  //   name: "encourage-study",
  //   meta: {
  //     title: "Khuyến học",
  //     permission: true,
  //   },
  // },
  {
    path: "sponsor",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/sponsor/index.vue"));
      });
    },
    name: "sponsor",
    meta: {
      title: "Đơn vị khuyến học/thiện nguyện",
      permission: true
    }
  }, {
    path: "sponsor-gift",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/sponsor-gift/index.vue"));
      });
    },
    name: "sponsor-gift",
    meta: {
      title: "Quà khuyến học/thiện nguyện",
      permission: true
    }
  }, {
    path: "sponsor-banner",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/scholarship-banner/sponsor.vue"));
      });
    },
    name: "sponsor-banner",
    meta: {
      title: "Banner",
      permission: true
    }
  }, {
    path: "sponsor-major",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/sponsor-major/index.vue"));
      });
    },
    name: "sponsor-major",
    meta: {
      title: "Chuyên ngành",
      permission: true
    }
  }, {
    path: "sponsor-condition",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/sponsor-condition/index.vue"));
      });
    },
    name: "sponsor-condition",
    meta: {
      title: "Quy định nhận quà khuyến học/ thiện nguyện",
      permission: true
    }
  }, {
    path: "sponsor-dictionary",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/sponsor-dictionary/index.vue"));
      });
    },
    name: "sponsor-dictionary",
    meta: {
      title: "Danh mục",
      permission: true
    }
  }, {
    path: "sponsor-gift-submit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/sponsor-gift-submit/index.vue"));
      });
    },
    name: "sponsor-gift-submit",
    meta: {
      title: "Yêu cầu nhận quà",
      permission: true
    }
  }, {
    path: "approve-sponsor",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/sponsor/index.vue"));
      });
    },
    name: "approve-sponsor",
    hidden: true,
    meta: {
      title: "Duyệt đơn vị tặng quà",
      permission: true
    }
  }, {
    path: "approve-sponsor-gift",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/sponsor-gift/index.vue"));
      });
    },
    name: "approve-sponsor-gift",
    hidden: true,
    meta: {
      title: "Duyệt Quà khuyến học/thiện nguyện",
      permission: true
    }
  }]
},
//Fclass medic
{
  path: "/fclass-medic",
  component: _layout.default,
  redirect: "/fclass-medic/index",
  meta: {
    title: "Medic",
    icon: "bank-fill",
    permission: true
  },
  permission: true,
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/medic/index.vue"));
      });
    },
    name: "medic-index",
    meta: {
      title: "Phòng khám - Bệnh viện",
      permission: true
    }
  }, {
    path: "medic-category",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/medic-category/index.vue"));
      });
    },
    name: "medic-category",
    meta: {
      title: "Chuyên khoa",
      permission: true
    }
  }, {
    path: "medic-service",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/medic-service/index.vue"));
      });
    },
    name: "medic-service",
    meta: {
      title: "Dịch vụ",
      permission: true
    }
  }, {
    path: "medic-pricing-service",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/medic-pricing-service/index.vue"));
      });
    },
    name: "medic-pricing-service",
    meta: {
      title: "Nhóm giá dịch vụ",
      permission: true
    }
  }, {
    path: "medic-banner",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/medic-banner/index.vue"));
      });
    },
    name: "medic-banner",
    meta: {
      title: "Banner",
      permission: true
    }
  }]
},
// Fclass meet
{
  path: "/fclass-meet",
  component: _layout.default,
  redirect: "/fclass-meet/index",
  meta: {
    title: "Fclass Meet",
    icon: "peoples",
    permission: true
  },
  permission: true,
  children: [{
    path: "/fclass-meet/index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/meet-connect/index.vue"));
      });
    },
    name: "connect",
    meta: {
      title: "Kết nối",
      permission: true
    }
  }, {
    path: "/fclass-meet/object",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/meet-object/index.vue"));
      });
    },
    name: "object",
    meta: {
      title: "Đối tượng",
      permission: true
    }
  }, {
    path: "/fclass-meet/config",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/meet-config/index.vue"));
      });
    },
    name: "config",
    meta: {
      title: "Cấu hình",
      permission: true
    }
  }]
},
// Fclass du lich & khach san
{
  path: "/fclass-hotel",
  component: _layout.default,
  redirect: "/fclass-hotel/index",
  meta: {
    title: "Fclass Du lịch & khách sạn",
    icon: "booking",
    permission: true
  },
  permission: true,
  children: [{
    path: "/fclass-hotel/index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/booking-hotel/index.vue"));
      });
    },
    name: "hotel",
    meta: {
      title: "Khách sạn",
      permission: true
    }
  }, {
    path: "/fclass-hotel/host",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/booking-host/index.vue"));
      });
    },
    name: "hotel-host",
    meta: {
      title: "Tài khoản chủ khách sạn",
      permission: true
    }
  }, {
    path: "/fclass-hotel/order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/booking-order/index.vue"));
      });
    },
    name: "hotel-order",
    meta: {
      title: "Đơn hàng",
      permission: true
    }
  },
  // {
  //   path: "/fclass-hotel/category",
  //   component: () => import("@/views/booking-category/index.vue"),
  //   name: "hotel-category",
  //   meta: {
  //     title: "Loại khách sạn",
  //     permission: true,
  //   },
  // },
  {
    path: "/fclass-hotel/dictionary",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/booking-dictionary/index.vue"));
      });
    },
    name: "hotel-dictionary",
    meta: {
      title: "Danh mục",
      permission: true
    }
  }, {
    path: "/fclass-hotel/banner",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/booking-banner/index.vue"));
      });
    },
    name: "hotel-banner",
    meta: {
      title: "Banner",
      permission: true
    }
  },
  // {
  //   path: "/fclass-hotel/util",
  //   component: () => import("@/views/booking-util/index.vue"),
  //   name: "hotel-util",
  //   meta: {
  //     title: "Tiện ích",
  //     permission: true,
  //   },
  // },
  {
    path: "/fclass-hotel/location",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/booking-location/index.vue"));
      });
    },
    name: "hotel-location",
    meta: {
      title: "Vị trí",
      permission: true
    }
  }, {
    path: "/fclass-hotel/policy",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/booking-policy/index.vue"));
      });
    },
    name: "hotel-policy",
    meta: {
      title: "Chính sách",
      permission: true
    }
  }, {
    path: "/fclass-hotel/voucher",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/booking-voucher/index.vue"));
      });
    },
    name: "hotel-voucher",
    meta: {
      title: "Voucher",
      permission: true
    }
  }
  // {
  //   path: "/fclass-hotel/promotion",
  //   component: () => import("@/views/booking-promotion/index.vue"),
  //   name: "hotel-promotion",
  //   meta: {
  //     title: "Khuyến mãi",
  //     permission: true,
  //   },
  // },
  ]
},
// Quy nhom
{
  path: "/group-fund",
  component: _layout.default,
  redirect: "/group-fund/index",
  meta: {
    title: "Quỹ nhóm",
    icon: "wallet",
    permission: true
  },
  hidden: process.env.NODE_ENV === "production",
  permission: process.env.NODE_ENV !== "production",
  children: [{
    path: "/group-fund/index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/wallet/index.vue"));
      });
    },
    name: "group-fund",
    meta: {
      title: "Danh sách quỹ nhóm",
      permission: true
    }
  }, {
    path: "/group-fund/transaction",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/wallet-transaction/index.vue"));
      });
    },
    name: "group-fund-transaction",
    meta: {
      title: "Lịch sử giao dịch",
      permission: true
    }
  }]
},
//Fclass mall
{
  path: "/mall",
  component: _layout.default,
  meta: {
    title: "Cửa hàng Mall",
    icon: "shopping-fill",
    permission: true
  },
  permission: true,
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mall"));
      });
    },
    name: "mall-index",
    meta: {
      title: "Cửa hàng Mall",
      permission: true
    },
    props: {
      isGetFromProvider: false
    }
  }, {
    path: "/update-mall-threshold-balance",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mall"));
      });
    },
    name: "mall-update-mall-threshold-balance",
    meta: {
      title: "Chỉnh sửa ngưỡng tín dụng",
      permission: true
    }
  }, {
    path: "/delete",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mall"));
      });
    },
    name: "mall-delete",
    hidden: true,
    meta: {
      title: "Xóa Mall",
      permission: true
    }
  }, {
    path: "/block",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mall"));
      });
    },
    name: "mall-block",
    hidden: true,
    meta: {
      title: "Khóa Mall",
      permission: true
    }
  }, {
    path: "export-excel",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mall"));
      });
    },
    name: "mall-export-excel",
    hidden: true,
    meta: {
      title: "Xuất excel Mall",
      permission: true
    }
  }, {
    path: "create",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mall"));
      });
    },
    name: "mall-create",
    hidden: true,
    meta: {
      title: "Thêm mới Mall",
      permission: true
    }
  }, {
    path: "/unblock",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mall"));
      });
    },
    name: "mall-unblock",
    hidden: true,
    meta: {
      title: "Mở khóa Mall",
      permission: true
    }
  }, {
    path: "/censor",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mall"));
      });
    },
    name: "mall-censor",
    hidden: true,
    meta: {
      title: "Duyệt Mall",
      permission: true
    }
  }, {
    path: "category",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mall-category/index.vue"));
      });
    },
    name: "mall-category",
    meta: {
      title: "Danh mục",
      permission: true
    }
  }, {
    path: "popular",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mall-search-popular/index.vue"));
      });
    },
    name: "mall-category-popular",
    meta: {
      title: "Tìm kiếm phổ biến",
      permission: true
    }
  }, {
    path: "banner",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/mall-banner"));
      });
    },
    name: "mall-banner",
    meta: {
      title: "Banner FclassMall",
      permission: true
    }
  }, {
    path: "city",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/address-mall/City"));
      });
    },
    name: "mall-address-city",
    meta: {
      title: "Tỉnh thành",
      permission: true
    }
  }, {
    path: "withdraw",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/withdraw-mall/index"));
      });
    },
    name: "mall-withdraw",
    meta: {
      title: "Rút tiền",
      permission: true
    }
  }, {
    path: "return-orders",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/return-orders/index"));
      });
    },
    name: "return-orders",
    meta: {
      title: "DS yêu cầu trả hàng",
      permission: true
    }
  }, {
    path: "refund-reason",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/refund-reason/index"));
      });
    },
    name: "refund-reason",
    meta: {
      title: "Lý do trả hàng/hoàn tiền",
      permission: true
    }
  }, {
    path: "config-collect-coin",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/config-collect-coin/index"));
      });
    },
    name: "config-collect-coin",
    meta: {
      title: "Thu thập coin",
      permission: true
    }
  }]
},
// Fclass Secondhand
{
  path: "/secondhand",
  component: _layout.default,
  meta: {
    title: "Cửa hàng Secondhand",
    icon: "shopping-fill",
    permission: true
  },
  hidden: process.env.NODE_ENV === "production",
  permission: process.env.NODE_ENV !== "production",
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/secondhand"));
      });
    },
    name: "secondhand-index",
    meta: {
      title: "Cửa hàng Secondhand",
      permission: true
    }
  }, {
    path: "/update-secondhand-threshold-balance",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/secondhand"));
      });
    },
    name: "secondhand-update-secondhand-threshold-balance",
    meta: {
      title: "Chỉnh sửa ngưỡng tín dụng",
      permission: true
    }
  }, {
    path: "/delete",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/secondhand"));
      });
    },
    name: "secondhand-delete",
    hidden: true,
    meta: {
      title: "Xóa Secondhand",
      permission: true
    }
  }, {
    path: "/block",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/secondhand"));
      });
    },
    name: "secondhand-block",
    hidden: true,
    meta: {
      title: "Khóa Secondhand",
      permission: true
    }
  }, {
    path: "export-excel",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/secondhand"));
      });
    },
    name: "secondhand-export-excel",
    hidden: true,
    meta: {
      title: "Xuất excel Secondhand",
      permission: true
    }
  }, {
    path: "create",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/secondhand"));
      });
    },
    name: "secondhand-create",
    hidden: true,
    meta: {
      title: "Thêm mới Secondhand",
      permission: true
    }
  }, {
    path: "/unblock",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/secondhand"));
      });
    },
    name: "secondhand-unblock",
    hidden: true,
    meta: {
      title: "Mở khóa Secondhand",
      permission: true
    }
  }, {
    path: "/censor",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/secondhand"));
      });
    },
    name: "secondhand-censor",
    hidden: true,
    meta: {
      title: "Duyệt Secondhand",
      permission: true
    }
  }, {
    path: "category",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/secondhand-category/index.vue"));
      });
    },
    name: "secondhand-category",
    meta: {
      title: "Danh mục",
      permission: true
    }
  }, {
    path: "popular",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/secondhand-search-popular/index.vue"));
      });
    },
    name: "secondhand-category-popular",
    meta: {
      title: "Tìm kiếm phổ biến",
      permission: true
    }
  }, {
    path: "banner",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/secondhand-banner"));
      });
    },
    name: "secondhand-banner",
    meta: {
      title: "Banner Secondhand",
      permission: true
    }
  }, {
    path: "city",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/address-secondhand/City"));
      });
    },
    name: "secondhand-address-city",
    meta: {
      title: "Tỉnh thành",
      permission: true
    }
  }, {
    path: "withdraw",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/withdraw-secondhand/index"));
      });
    },
    name: "secondhand-withdraw",
    meta: {
      title: "Rút tiền",
      permission: true
    }
  }, {
    path: "deposit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/deposit-secondhand/index"));
      });
    },
    name: "secondhand-deposit",
    meta: {
      title: "Nạp tiền",
      permission: true
    }
  }, {
    path: "return-orders",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/return-orders-secondhand/index"));
      });
    },
    name: "return-orders-secondhand",
    meta: {
      title: "DS yêu cầu trả hàng",
      permission: true
    }
  }, {
    path: "refund-reason",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/refund-reason-secondhand/index"));
      });
    },
    name: "refund-reason-secondhand",
    meta: {
      title: "Lý do trả hàng/hoàn tiền",
      permission: true
    }
  }]
}, {
  path: "/section",
  component: _layout.default,
  name: "section-index",
  hidden: true,
  meta: {
    title: "Portal",
    permission: false
  },
  children: [{
    path: "bio-detail",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/driver-profile/detail/section/BioDetail.vue"));
      });
    },
    name: "section-bio-detail",
    meta: {
      title: "Bio Detail",
      permission: false
    }
  }, {
    path: "identity-card",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/driver-profile/detail/section/IdentityCard.vue"));
      });
    },
    name: "section-identity-card",
    meta: {
      title: "Identify Card",
      permission: false
    }
  }, {
    path: "driving-license",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/driver-profile/detail/section/DrivingLicense.vue"));
      });
    },
    name: "section-driving-license",
    meta: {
      title: "Driving License",
      permission: false
    }
  }, {
    path: "other",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/driver-profile/detail/section/Other.vue"));
      });
    },
    name: "section-other",
    meta: {
      title: "Other",
      permission: false
    }
  }, {
    path: "vehicle-info",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/driver-profile/detail/section/VehicleInfo.vue"));
      });
    },
    name: "section-vehicle-info",
    meta: {
      title: "Vehicle Info",
      permission: false
    }
  }, {
    path: "bank",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/driver-profile/detail/section/Bank.vue"));
      });
    },
    name: "section-bank",
    meta: {
      title: "Bank",
      permission: false
    }
  }]
},
// {
//   path: "/driver-vip",
//   component: Layout,
//   redirect: "/driver-vip/index",
//   meta: {
//     title: "Đối tác chuyên nghiệp",
//     icon: "compass-fill",
//     permission: true
//   },
//   permission: true,
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/vip-partner"),
//       name: "driver-vip",
//       meta: {
//         title: "Đối tác chuyên nghiệp",
//         permission: true
//       }
//     }
//   ]
// },
{
  path: "/driver",
  component: _layout.default,
  redirect: "/driver/index",
  meta: {
    title: "Tài xế",
    icon: "compass-fill",
    permission: true
  },
  permission: true,
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/driver/index"));
      });
    },
    name: "driver-index",
    meta: {
      title: "Tài xế",
      permission: true
    }
  }, {
    path: "export-driver-excel",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/driver"));
      });
    },
    name: "driver-export-excel",
    hidden: true,
    meta: {
      title: "Xuất excel tài xế",
      permission: true
    }
  }, {
    path: "profile",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/driver-profile"));
      });
    },
    name: "driver-profile-index",
    meta: {
      title: "Hồ sơ đăng ký",
      permission: true
    }
  },
  // {
  //   path: "status-driver-profile",
  //   component: () => import("@/views/driver-profile"),
  //   name: "status-driver-profile",
  //   hidden: true,
  //   meta: {
  //     title: "Duyệt tình trạng hồ sơ",
  //     permission: true,
  //   },
  // },

  {
    path: "approve-driver-profile",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/driver-profile"));
      });
    },
    name: "approve-driver-profile",
    hidden: true,
    meta: {
      title: "Duyệt chấp nhận hồ sơ",
      permission: true
    }
  }, {
    path: "profile-censor",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/driver-profile"));
      });
    },
    name: "driver-profile-censor",
    hidden: true,
    meta: {
      title: "Duyệt tình trạng hồ sơ",
      permission: true
    }
  }, {
    path: "delete-driver-profile",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/driver-profile"));
      });
    },
    name: "delete-driver-profile",
    hidden: true,
    meta: {
      title: "Xóa hồ sơ",
      permission: true
    }
  }, {
    path: "profile-become",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/driver-profile"));
      });
    },
    name: "driver-profile-censor-become",
    hidden: true,
    meta: {
      title: "Duyệt thành tài xế",
      permission: true
    }
  }, {
    path: "professional",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/vip-partner"));
      });
    },
    name: "driver-vip",
    meta: {
      title: "Đối tác chuyên nghiệp",
      permission: true
    }
  }, {
    path: "reward",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/reward-driver"));
      });
    },
    name: "driver-reward",
    meta: {
      title: "Thưởng đối tác",
      permission: true
    }
  }, {
    path: "reward-ref",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/reward-ref-driver"));
      });
    },
    name: "driver-reward-ref",
    meta: {
      title: "Chính sách MKT 4G",
      permission: true
    }
  },
  // {
  //   path: "reward-sale",
  //   component: () => import("@/views/config-reward"),
  //   name: "driver-reward",
  //   meta: {
  //     title: "Thưởng hoàn thành đơn",
  //     permission: true
  //   }
  // },
  // {
  //   path: "income-hour",
  //   component: () => import("@/views/guarant-bonus-every-hour"),
  //   name: "income-hour",
  //   meta: {
  //     title: "Thưởng theo giờ",
  //     permission: true
  //   }
  // },
  {
    path: "profile/detail",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/driver-profile/detail"));
      });
    },
    name: "driver-detail-profile-index",
    hidden: true,
    meta: {
      title: "Hồ sơ tài xế",
      permission: false
    }
  }, {
    path: "export-excel",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/driver-profile"));
      });
    },
    name: "driver-role-export-excel",
    hidden: true,
    meta: {
      title: "Xuất excel Hồ sơ đăng ký",
      permission: true
    }
  }, {
    path: "approve-all",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/driver-profile"));
      });
    },
    name: "driver-role-approve-all",
    hidden: true,
    meta: {
      title: "Duyệt hàng loạt Hồ sơ đăng ký",
      permission: true
    }
  },
  //  {
  //    path: "transport-business",
  //    component: () => import("@/views/transport-business"),
  //    name: "transport-business-index",
  //    meta: {
  //      title: "Đơn vị KDVT"
  //    }
  //  },
  {
    path: "deposit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/deposit/index"));
      });
    },
    name: "driver-deposit",
    meta: {
      title: "Nạp tiền",
      permission: true
    }
  }, {
    path: "withdraw",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/withdraw/index"));
      });
    },
    name: "driver-withdraw",
    meta: {
      title: "Rút tiền",
      permission: true
    }
  }, {
    path: "transfer-balance",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/transfer-balance/index"));
      });
    },
    name: "driver-transfer-balance",
    meta: {
      title: "Chuyển tiền",
      permission: true
    }
  }]
}, {
  path: "/company",
  component: _layout.default,
  redirect: "/company/index",
  meta: {
    title: "Tổ chức & Hộ gia đình",
    icon: "bank-fill",
    permission: true
  },
  permission: true,
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/company/index"));
      });
    },
    name: "company-index",
    meta: {
      title: "Tổ chức & Hộ gia đình",
      permission: true
    }
  }, {
    path: "edit-credit-limit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/company/index"));
      });
    },
    name: "Company-edit-credit-limit",
    hidden: true,
    meta: {
      title: "Điều chỉnh định mức CREDIT",
      permission: true
    }
  }, {
    path: "export-excel",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/company/index"));
      });
    },
    name: "Company-export",
    hidden: true,
    meta: {
      title: "Xuất excel Tổ chức & Hộ gia đình",
      permission: true
    }
  }, {
    path: "deposit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/deposit-company/index"));
      });
    },
    name: "company-deposit",
    meta: {
      title: "Nạp tiền",
      permission: true
    }
  }, {
    path: "withdraw",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/withdraw-company/index"));
      });
    },
    name: "company-withdraw",
    meta: {
      title: "Rút tiền",
      permission: true
    }
  }]
}, {
  path: "/chat",
  component: _layout.default,
  redirect: "/chat/user",
  meta: {
    title: "Chat",
    icon: "comments-solid",
    permission: true
  },
  permission: true,
  children: [{
    path: "user",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/chat-user/index"));
      });
    },
    name: "customer-chat",
    meta: {
      title: "Chat User",
      permission: true
    }
  }, {
    path: "driver",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/chat-driver/index"));
      });
    },
    name: "driver-chat",
    meta: {
      title: "Chat Driver",
      permission: true
    }
  }]
}, {
  path: "/withdraw-request",
  component: _layout.default,
  redirect: "/withdraw-request/index",
  meta: {
    title: "Yêu cầu rút tiền",
    icon: "bank-fill",
    permission: true
  },
  permission: true,
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/withdraw-request/index"));
      });
    },
    name: "withdraw-request-index",
    meta: {
      title: "Yêu cầu rút tiền",
      permission: true
    }
  }]
},
// {
//   path: "/branch",
//   component: Layout,
//   redirect: "/branch/index",
//   meta: {
//     title: "Chi nhánh",
//     icon: "bank-fill",
//     permission: true
//   },
//   permission: true,
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/branch/index"),
//       name: "Branch",
//       meta: {
//         title: "Chi nhánh",
//         permission: true
//       }
//     }
//   ]
// },

{
  path: "/news",
  component: _layout.default,
  redirect: "/news/index",
  meta: {
    title: "Truyền thông",
    icon: "read-fill",
    permission: true
  },
  permission: true,
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/news/index"));
      });
    },
    name: "news-index",
    meta: {
      title: "Tin tức",
      permission: true
    }
  }, {
    path: "notification",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/driver-notification/index"));
      });
    },
    name: "news-driver-notification",
    meta: {
      title: "Tin nhắn gửi tài xế",
      permission: true
    }
  }, {
    path: "export-excel",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/news"));
      });
    },
    name: "news-export-excel",
    hidden: true,
    meta: {
      title: "Xuất excel thông báo tài xế",
      permission: true
    }
  }, {
    path: "banner",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/banner/index"));
      });
    },
    name: "banner-index",
    meta: {
      title: "Tin tức - banner",
      permission: true
    }
  }, {
    path: "popup",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/Popup/index"));
      });
    },
    name: "popup-index",
    meta: {
      title: "Popup",
      permission: true
    }
  }, {
    path: "export-excel",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/banner"));
      });
    },
    name: "banner-export-excel",
    hidden: true,
    meta: {
      title: "Xuất excel tin tức",
      permission: true
    }
  }, {
    path: "customer-notification",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/notification"));
      });
    },
    name: "notification-customer-index",
    meta: {
      title: "Tin nhắn gửi khách hàng",
      permission: true
    }
  }, {
    path: "export-notification-excel",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/notification"));
      });
    },
    name: "notification-customer-export-excel",
    hidden: true,
    meta: {
      title: "Xuất excel tin nhắn gửi khách hàng",
      permission: true
    }
  }, {
    path: "promotion",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/promotion/index"));
      });
    },
    name: "promotion-index",
    meta: {
      title: "Khuyến mãi",
      permission: true
    }
  }, {
    path: "export-promotion-excel",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/promotion"));
      });
    },
    name: "promotion-export-excel",
    hidden: true,
    meta: {
      title: "Xuất excel khuyến mãi",
      permission: true
    }
  }, {
    path: "voucher",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/voucher/index"));
      });
    },
    name: "customer-voucher",
    meta: {
      title: "Voucher",
      permission: true
    }
  }]
}, {
  path: "/part-time-job",
  component: _layout.default,
  redirect: "/part-time-job/index",
  meta: {
    title: "Fan Fclass",
    icon: "documentation",
    permission: true
  },
  permission: true,
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/part-time-job"));
      });
    },
    name: "part-time-jobs-index",
    meta: {
      title: "Fan Fclass",
      permission: true
    }
  }]
}, {
  path: "/accountant",
  component: _layout.default,
  redirect: "/accountant/index",
  meta: {
    title: "Kế toán",
    icon: "documentation",
    permission: true
  },
  permission: true,
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/accountant/Accountant"));
      });
    },
    name: "accountant-index",
    meta: {
      title: "Dạng excel",
      permission: true
    }
  }, {
    path: "order-transport",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/accountant/OrderTransport"));
      });
    },
    name: "accountant-order-transport",
    meta: {
      title: "Đơn gọi xe",
      permission: true
    }
  }, {
    path: "order-delivery",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/accountant/OrderDelivery"));
      });
    },
    name: "accountant-order-delivery",
    meta: {
      title: "Đơn giao hàng",
      permission: true
    }
  }, {
    path: "order-food",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/accountant/OrderFood"));
      });
    },
    name: "accountant-order-food",
    meta: {
      title: "Đơn giao thức ăn",
      permission: true
    }
  }, {
    path: "order-draw",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/accountant/IdealOrder"));
      });
    },
    name: "accountant-order-draw",
    meta: {
      title: "Đơn bốc thăm",
      permission: true
    }
  }, {
    path: "order-mall",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/accountant/MallOrder"));
      });
    },
    name: "accountant-order-mall",
    meta: {
      title: "Đơn MALL",
      permission: true
    }
  }, {
    path: "driver-deposit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/accountant/DriverDeposit"));
      });
    },
    name: "accountant-diver-deposit",
    meta: {
      title: "Nạp tiền - tài xế",
      permission: true
    }
  }, {
    path: "customer-deposit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/accountant/CustomerDeposit"));
      });
    },
    name: "accountant-customer-deposit",
    meta: {
      title: "Nạp tiền - người dùng",
      permission: true
    }
  }, {
    path: "store-deposit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/accountant/StoreDeposit"));
      });
    },
    name: "accountant-store-deposit",
    meta: {
      title: "Nạp tiền - F&B",
      permission: true
    }
  }, {
    path: "driver-withdraw",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/accountant/DriverWithdraw"));
      });
    },
    name: "accountant-diver-withdraw",
    meta: {
      title: "Rút tiền - tài xế",
      permission: true
    }
  }, {
    path: "customer-withdraw",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/accountant/CustomerWithdraw"));
      });
    },
    name: "accountant-customer-withdraw",
    meta: {
      title: "Rút tiền - người dùng",
      permission: true
    }
  }, {
    path: "store-withdraw",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/accountant/StoreWithdraw"));
      });
    },
    name: "accountant-store-withdraw",
    meta: {
      title: "Rút tiền - F&B",
      permission: true
    }
  }, {
    path: "mall-withdraw",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/accountant/MallWithdraw"));
      });
    },
    name: "accountant-mall-withdraw",
    meta: {
      title: "Rút tiền - Mall",
      permission: true
    }
  }, {
    path: "driver-transaction",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/accountant/DriverTransaction"));
      });
    },
    name: "accountant-driver-transaction",
    meta: {
      title: "Giao dịch - tài xế",
      permission: true
    }
  }, {
    path: "customer-transaction",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/accountant/CustomerTransaction"));
      });
    },
    name: "accountant-customer-transaction",
    meta: {
      title: "Giao dịch - người dùng",
      permission: true
    }
  }, {
    path: "store-transaction",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/accountant/StoreTransaction"));
      });
    },
    name: "accountant-store-transaction",
    meta: {
      title: "Giao dịch - F&B",
      permission: true
    }
  }, {
    path: "mall-transaction",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/accountant/MallTransaction"));
      });
    },
    name: "accountant-mall-transaction",
    meta: {
      title: "Giao dịch - Mall",
      permission: true
    }
  }]
}, {
  path: "/report-BCT",
  component: _layout.default,
  redirect: "/report-BCT/index",
  meta: {
    title: "Báo cáo BCT",
    icon: "documentation",
    permission: true
  },
  permission: true,
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/reportBTC"));
      });
    },
    name: "report-BCT",
    meta: {
      title: "Báo cáo BCT",
      permission: true
    }
  }]
}, {
  path: "/vehicle",
  component: _layout.default,
  redirect: "/vehicle/index",
  meta: {
    title: "Phương tiện",
    icon: "car-fill",
    permission: true
  },
  permission: true,
  children: [{
    path: "group",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/vehicle-group"));
      });
    },
    name: "vehicle-group",
    meta: {
      title: "Nhóm phương tiện",
      permission: true
    }
  }, {
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/vehicle/index"));
      });
    },
    name: "vehicle-index",
    meta: {
      title: "Cấu hình giá",
      permission: true
    }
  }, {
    path: "config",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/vehicle-config"));
      });
    },
    name: "vehicle-config",
    meta: {
      title: "Cấu hình xe",
      permission: true
    }
  }]
}, {
  path: "/profile",
  component: _layout.default,
  redirect: "/profile/index",
  permission: true,
  hidden: true,
  meta: {
    title: "Cá nhân",
    icon: "smile-fill",
    permission: false
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/profile/index"));
      });
    },
    name: "profile-index",
    meta: {
      title: "Trang cá nhân",
      permission: false
    }
  }]
}, {
  path: "/test-map",
  component: _layout.default,
  redirect: "/test-map/index",
  permission: false,
  hidden: true,
  meta: {
    title: "test map",
    icon: "smile-fill",
    permission: false
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/test-map"));
      });
    },
    name: "test-map",
    meta: {
      permission: false,
      title: "test map"
    }
  }]
}, {
  path: "/op",
  component: _layout.default,
  redirect: "/op/index",
  permission: true,
  meta: {
    title: "Vận hành",
    icon: "smile-fill",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/operate"));
      });
    },
    name: "operate-index",
    meta: {
      permission: true,
      title: "Thơi gian thực"
    }
  }, {
    path: "activation",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/operate-activation"));
      });
    },
    name: "operate-activation-index",
    meta: {
      permission: true,
      title: "Tra cứu"
    }
  }]
}, {
  path: "/user",
  component: _layout.default,
  redirect: "/user/index",
  permission: true,
  meta: {
    title: "Nhân viên",
    icon: "id-card-fill",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user/index"));
      });
    },
    name: "user-index",
    meta: {
      title: "Nhân viên",
      permission: true
    }
  }, {
    path: "role",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/permission/role"));
      });
    },
    name: "user-permission-role",
    meta: {
      title: "Quyền",
      permission: true
    }
  }, {
    path: "branch",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/branch/index"));
      });
    },
    name: "user-branch",
    meta: {
      title: "Chi nhánh",
      permission: true
    }
  }]
}, {
  path: "/config",
  component: _layout.default,
  redirect: "/config/index",
  meta: {
    title: "Cấu hình",
    icon: "setting-fill",
    permission: true
  },
  permission: true,
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/config/index"));
      });
    },
    name: "config-index",
    meta: {
      title: "Chung",
      permission: true
    }
  }, {
    path: "share",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/config-share/index"));
      });
    },
    name: "config-share",
    meta: {
      title: "Chia sẻ",
      permission: true
    }
  }, {
    path: "share-edit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/config-share/index"));
      });
    },
    name: "config-share-edit-image",
    hidden: true,
    meta: {
      title: "Chỉnh sửa hình ảnh chia sẻ",
      permission: true
    }
  }, {
    path: "tip",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/tip/index"));
      });
    },
    name: "config-tip",
    meta: {
      title: "Tip",
      permission: true
    }
  }, {
    path: "merchandise",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/merchantdise/index"));
      });
    },
    name: "config-merchantdise",
    meta: {
      title: "Loại hàng hóa",
      permission: true
    }
  }, {
    path: "merchandise-unit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/merchantdise-unit/index"));
      });
    },
    name: "config-merchantdise-unit",
    meta: {
      title: "Đơn vị tính",
      permission: true
    }
  }, {
    path: "city",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/address/City"));
      });
    },
    name: "config-address-city",
    meta: {
      title: "Tỉnh thành",
      permission: true
    }
  }, {
    path: "customer",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/address-customer/City"));
      });
    },
    name: "config-customer-register",
    meta: {
      title: "Thưởng khách hàng đăng ký",
      permission: true
    }
  }, {
    path: "surcharge",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/surcharge"));
      });
    },
    name: "config-surcharge",
    hidden: false,
    meta: {
      title: "Phụ thu",
      permission: true
    }
  }, {
    path: "app-feature",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/app-feature-config/index.vue"));
      });
    },
    name: "config-app-feature",
    hidden: false,
    meta: {
      title: "Tính năng app",
      permission: true
    }
  }]
}, {
  path: "*",
  redirect: "/404",
  hidden: true
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
var asyncRoutes = exports.asyncRoutes = [
  // 404 page must be placed at the end !!!
  // {
  //   path: '*',
  //   redirect: '/404',
  //   hidden: true
  // }
];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;