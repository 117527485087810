"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.createPromotion = createPromotion;
exports.findAll = findAll;
var _request = _interopRequireDefault(require("@/utils/request"));
function findAll(listQuery) {
  return (0, _request.default)({
    url: "/deposits",
    method: "get",
    params: listQuery
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/deposits",
    method: "post",
    data: data
  });
}
function createPromotion(data) {
  return (0, _request.default)({
    url: "/deposits/promotion",
    method: "post",
    data: data
  });
}