"use strict";

var _interopRequireWildcard = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es.array.map.js");
var company = _interopRequireWildcard(require("@/api/company"));
var _date = require("@/utils/date");
var _format = require("@/utils/format");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _AddMemberCompanyDialog = _interopRequireDefault(require("./AddMemberCompanyDialog.vue"));
var _ImportMemberCompanyModal = _interopRequireDefault(require("./ImportMemberCompanyModal.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Pagination: _Pagination.default,
    AddMemberCompanyDialog: _AddMemberCompanyDialog.default,
    ImportMemberCompanyModal: _ImportMemberCompanyModal.default
  },
  data: function data() {
    return {
      dialogLoading: false,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      tempData: {},
      tableData: [],
      avatar: "",
      dialogStatus: "",
      dialogWidth: "1200px",
      dialogVisible: false,
      loadingButton: false,
      tabActive: "info",
      tableLoading: false,
      total: 0,
      listQuery: {
        limit: 50,
        page: 1
      },
      customers: []
    };
  },
  created: function created() {},
  methods: {
    handleImport: function handleImport() {
      this.$refs.ImportMemberCompanyModal.handleCreate();
    },
    formatNumberVN: function formatNumberVN(n) {
      return (0, _format.formatNumberVN)(n);
    },
    formatDate: function formatDate(n) {
      return (0, _date.formatDate)(n);
    },
    formatDateTime: function formatDateTime(n) {
      return (0, _date.formatDateTime)(n);
    },
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    getList: function getList() {
      var _this = this;
      this.tableLoading = true;
      company.getListMembers(this.tempData.id, this.listQuery).then(function (res) {
        _this.tableData = res.data.members;
        _this.total = res.data.total;
        _this.customers = res.data.members.map(function (e) {
          return e.id;
        });
      }).then(function () {
        _this.tableLoading = false;
      });
    },
    handleView: function handleView(data) {
      var _this2 = this;
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this2.tempData = (0, _objectSpread2.default)({}, data);
        _this2.getList();
      });
    },
    handleCreate: function handleCreate() {
      this.$refs["customer-dialog"].handleCreate(this.tempData);
    },
    handleUpdate: function handleUpdate(row) {
      this.$refs["customer-dialog"].handleUpdate(row);
    },
    handleRemove: function handleRemove(data) {
      var _this3 = this;
      this.$confirm("Thành viên này sẽ bị xóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        company.removeMember(_this3.tempData.id, {
          customerId: data.customer.id
        }).then(function () {
          _this3.getList();
          _this3.$message({
            type: "success",
            message: "Xóa thành công"
          });
        });
      });
    }
  }
};