import { render, staticRenderFns } from "./AgentWithdraw.vue?vue&type=template&id=644a2aa8&scoped=true"
import script from "./AgentWithdraw.vue?vue&type=script&lang=ts"
export * from "./AgentWithdraw.vue?vue&type=script&lang=ts"
import style0 from "./AgentWithdraw.vue?vue&type=style&index=0&id=644a2aa8&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "644a2aa8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/mac/Documents/project/028/028admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('644a2aa8')) {
      api.createRecord('644a2aa8', component.options)
    } else {
      api.reload('644a2aa8', component.options)
    }
    module.hot.accept("./AgentWithdraw.vue?vue&type=template&id=644a2aa8&scoped=true", function () {
      api.rerender('644a2aa8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/agent-withdraw/AgentWithdraw.vue"
export default component.exports