"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bannerApi = void 0;
exports.create = create;
exports.findAll = findAll;
exports.remove = remove;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
function findAll(params) {
  return (0, _request.default)({
    url: "/popup",
    params: params
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/popup",
    method: "post",
    data: data
  });
}
function update(id, data) {
  return (0, _request.default)({
    url: "/popup/".concat(id),
    method: "patch",
    data: data
  });
}
function remove(id) {
  return (0, _request.default)({
    url: "/popup/".concat(id),
    method: "delete"
  });
}
var bannerApi = exports.bannerApi = {
  findAll: findAll,
  create: create,
  update: update,
  remove: remove
};