"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AgentStatusTrans = exports.AgentStatus = exports.AgentKYCType = exports.AgentKYCStatusTrans = exports.AgentKYCStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var AgentKYCStatus;
(function (AgentKYCStatus) {
  AgentKYCStatus["Pending"] = "PENDING";
  AgentKYCStatus["Approve"] = "APPROVE";
  AgentKYCStatus["Reject"] = "REJECT";
})(AgentKYCStatus || (exports.AgentKYCStatus = AgentKYCStatus = {}));
var AgentKYCType;
(function (AgentKYCType) {
  AgentKYCType["Diamond"] = "DIAMOND";
  AgentKYCType["Platinum"] = "PLATINUM";
  AgentKYCType["Titan"] = "TITAN";
  AgentKYCType["Gold"] = "GOLD";
  AgentKYCType["Silver"] = "SILVER";
  AgentKYCType["Bronze"] = "BRONZE";
})(AgentKYCType || (exports.AgentKYCType = AgentKYCType = {}));
var AgentStatus;
(function (AgentStatus) {
  AgentStatus["Pending"] = "PENDING";
  AgentStatus["Approve"] = "APPROVE";
  AgentStatus["Reject"] = "REJECT";
})(AgentStatus || (exports.AgentStatus = AgentStatus = {}));
var AgentStatusTrans = exports.AgentStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, AgentStatus.Pending, {
  label: "Chưa duyệt",
  color: '',
  value: AgentStatus.Pending
}), AgentStatus.Approve, {
  label: "Đã duyệt",
  color: 'success',
  value: AgentStatus.Approve
}), AgentStatus.Reject, {
  label: "Bị từ chối",
  color: 'danger',
  value: AgentStatus.Reject
});
var AgentKYCStatusTrans = exports.AgentKYCStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, AgentKYCStatus.Pending, {
  label: "Chờ duyệt",
  color: '',
  value: AgentKYCStatus.Pending
}), AgentKYCStatus.Approve, {
  label: "Đã duyệt",
  color: 'success',
  value: AgentKYCStatus.Approve
}), AgentKYCStatus.Reject, {
  label: "Bị từ chối",
  color: 'danger',
  value: AgentKYCStatus.Reject
});