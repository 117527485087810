"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _configShare = require("@/api/config-share");
var _enum = require("@/utils/enum");
var _configuration = require("@/types/configuration");
var _ContentModal = _interopRequireDefault(require("./components/ContentModal.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    ContentModal: _ContentModal.default
  },
  data: function data() {
    return {
      configSystem: process.env.VUE_APP_CONFIG_SYSTEM,
      SHARE_CONFIG_TRANS: _enum.SHARE_CONFIG_TRANS,
      ConfigurationType: _configuration.ConfigurationType,
      formData: {},
      isLoading: false,
      loadingButton: false,
      tip: {},
      dialogStatus: "",
      dialogVisible: false,
      tableDataContent: [],
      type: "" //'content' | 'config',
    };
  },
  created: function created() {
    this.getData();
  },
  methods: {
    handleEdit: function handleEdit(row) {
      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.type = "content";
      this.$refs.ContentModal.handleUpdate(row);
    },
    handleAdd: function handleAdd() {
      this.$refs.ContentModal.handleCreate();
    },
    getData: function getData() {
      var _this = this;
      this.isLoading = true;
      _configShare.shareConfigApi.findAll().then(function (res) {
        _this.tableDataContent = res.data.shareConfigs;
      }).finally(function () {
        _this.isLoading = false;
      });
    }
  }
};