"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _moment = _interopRequireDefault(require("moment"));
var _vueCountTo = _interopRequireDefault(require("vue-count-to"));
var _agentDashboard = require("@/api/agentDashboard.api");
var _default = exports.default = {
  components: {
    CountTo: _vueCountTo.default
  },
  data: function data() {
    return {
      datePicker: [],
      dataSource: {},
      pickerOptions: {
        shortcuts: [{
          text: "Tuần trước",
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          }
        }, {
          text: "Tháng trước",
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          }
        }, {
          text: "3 tháng trước",
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          }
        }]
      },
      date: [(0, _moment.default)().subtract(1, "months").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")]
    };
  },
  props: ["listQuery"],
  watch: {
    date: function date(newVal) {
      this.getData();
    },
    listQuery: {
      handler: function handler(val) {
        this.getData();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getData: function getData() {
      var _this = this;
      var data = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        fromAt: this.date ? (0, _moment.default)(this.date[0]).unix() : undefined,
        toAt: this.date ? (0, _moment.default)(this.date[1]).unix() : undefined,
        status: this.status
      });
      _agentDashboard.agentDashboardApi.summary(data).then(function (res) {
        _this.dataSource = res.data;
      });
    } // queryDate(e) {
    //   if (e) {
    //     this.listQuery.fromAt = moment(e[0]).startOf("day").unix();
    //     this.listQuery.toAt = moment(e[1]).endOf("day").unix();
    //   } else {
    //     this.listQuery.fromAt = undefined;
    //     this.listQuery.toAt = undefined;
    //   }
    //   this.getData();
    // },
  },
  created: function created() {
    // this.getData();
  }
};