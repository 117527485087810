"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.appFeatureConfigApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var appFeatureConfigApi = exports.appFeatureConfigApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/appFeatureConfig',
      params: params
    });
  },
  updatePosition: function updatePosition(data) {
    return (0, _request.default)({
      url: "/appFeatureConfig/position",
      method: 'patch',
      data: data
    });
  },
  update: function update(data, id) {
    return (0, _request.default)({
      url: "/appFeatureConfig/".concat(id),
      method: 'patch',
      data: data
    });
  }
};