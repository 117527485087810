var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
              _c("el-input", {
                attrs: { placeholder: "Tìm tiêu đề" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.getList($event)
                  },
                },
                model: {
                  value: _vm.listQuery.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "search", $$v)
                  },
                  expression: "listQuery.search",
                },
              }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { transform: "translateY(10px)" },
              attrs: { type: "primary" },
              on: { click: _vm.handleCreate },
            },
            [_vm._v(" Thêm popup ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingList,
              expression: "loadingList",
            },
          ],
          attrs: { data: _vm.tableData, border: "", stripe: "", fit: "" },
        },
        [
          _c("el-table-column", { attrs: { type: "index" } }),
          _c("el-table-column", {
            attrs: { label: "Tiêu đề" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.image
                      ? _c("img", {
                          staticStyle: { "border-radius": "8px" },
                          attrs: {
                            src: _vm.$url.image(row.image),
                            width: "150",
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    _c("br"),
                    _c("label", { domProps: { innerHTML: _vm._s(row.name) } }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Mô tả ngắn" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.shortContent) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Tỉnh thành" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.city && row.city.name) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatDateTime(row.dateCreated)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động", width: "230" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(row)
                          },
                        },
                      },
                      [_vm._v(" Chi tiết ")]
                    ),
                    !row.isVisible
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "warning", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleShow(row)
                              },
                            },
                          },
                          [_vm._v(" Hiện ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleHide(row)
                              },
                            },
                          },
                          [_vm._v(" Ẩn ")]
                        ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(row)
                          },
                        },
                      },
                      [_vm._v(" Xóa ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title:
              _vm.dialogStatus == "update" ? "Cập nhật popup" : "Thêm popup",
            visible: _vm.dialogVisible,
            width: "1200px",
            fullscreen: "",
            top: "15px",
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.rules,
                    "label-width": "80px",
                    "label-position": "top",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Tên popup",
                                        prop: "name",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.formData.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.formData, "name", $$v)
                                          },
                                          expression: "formData.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Mô tả ngắn",
                                        prop: "shortContent",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { type: "textarea", rows: 3 },
                                        model: {
                                          value: _vm.formData.shortContent,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "shortContent",
                                              $$v
                                            )
                                          },
                                          expression: "formData.shortContent",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Tỉnh/Thành",
                                        prop: "cityId",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: { filterable: "" },
                                          model: {
                                            value: _vm.formData.cityId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "cityId",
                                                $$v
                                              )
                                            },
                                            expression: "formData.cityId",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "Tất cả",
                                              value: null,
                                            },
                                          }),
                                          _vm._l(_vm.cities, function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.nameWithType,
                                                value: item.id,
                                              },
                                            })
                                          }),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Loại", prop: "mode" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: true,
                                            filterable: "",
                                          },
                                          model: {
                                            value: _vm.formData.mode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "mode",
                                                $$v
                                              )
                                            },
                                            expression: "formData.mode",
                                          },
                                        },
                                        _vm._l(
                                          _vm.PopupMode,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: _vm.PopupModeTrans[item],
                                                value: item,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.formData.mode ==
                                  _vm.PopupMode.IdealProduct
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Chiến dịch Fclass draw",
                                            prop: "idealProductId",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                clearable: true,
                                                filterable: "",
                                                remote: "",
                                                "remote-method":
                                                  _vm.searchIdealProduct,
                                              },
                                              model: {
                                                value:
                                                  _vm.formData.idealProductId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "idealProductId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.idealProductId",
                                              },
                                            },
                                            _vm._l(
                                              _vm.idealProducts,
                                              function (item, index) {
                                                return _c(
                                                  "el-option",
                                                  {
                                                    key: index,
                                                    attrs: {
                                                      label: item.name,
                                                      value: item.id,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          float: "left",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          float: "right",
                                                          color: "#1890ff",
                                                          "font-size": "13px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.code)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.formData.mode == _vm.PopupMode.AppFeature
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Tính năng app",
                                            prop: "appFeatureConfigId",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                clearable: true,
                                                filterable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.formData
                                                    .appFeatureConfigId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "appFeatureConfigId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.appFeatureConfigId",
                                              },
                                            },
                                            _vm._l(
                                              _vm.appConfigs,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label:
                                                      _vm.appFeatureTypeTrans[
                                                        item.appFeature
                                                      ],
                                                    value: item.id,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { span: 24 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label:
                                          "Hình ảnh (khuyến nghị: 428px x 728px, dung lượng tối đa 1MB)",
                                        prop: "image",
                                      },
                                    },
                                    [
                                      _c("SingleImageS3", {
                                        attrs: {
                                          pathImage: _vm.formData.image,
                                          width: 360,
                                          sizeLimit: 1,
                                          height: 144,
                                          prefixFile: _vm.PrefixImage.Banner,
                                        },
                                        on: {
                                          "upload:success":
                                            _vm.handleUploadSuccess,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Mô tả", prop: "content" } },
                            [
                              _c("Tinymce", {
                                ref: "editor",
                                attrs: { width: "100%", height: 400 },
                                on: { onInit: _vm.handleContentInit },
                                model: {
                                  value: _vm.formData.content,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "content", $$v)
                                  },
                                  expression: "formData.content",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.submitLoading, type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }