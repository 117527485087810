"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapService = exports.DirectionMode = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _moment = _interopRequireDefault(require("moment"));
// const GOOGLE_KEY = "AIzaSyCYTpAP8hbPNtBSGEMkU-6u1I1FJp2AHK4";
var DirectionMode;
(function (DirectionMode) {
  DirectionMode["Driving"] = "driving";
  DirectionMode["Transit"] = "transit";
})(DirectionMode || (exports.DirectionMode = DirectionMode = {}));
var mapService = exports.mapService = {
  getDirection: getDirection,
  getPlaceDetail: getPlaceDetail,
  findByQuery: findByQuery
};
function findByQuery(text) {
  return (0, _request.default)({
    url: "/ggMap/findByQuery",
    method: "get",
    params: {
      text: text
    }
  });
}
function getDirection(origin, destination, waypoints) {
  var mode = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : DirectionMode.Driving;
  return (0, _request.default)({
    url: "/ggMap/getDirection",
    method: "get",
    params: {
      origin: origin,
      destination: destination,
      mode: mode,
      waypoints: waypoints,
      traffic_model: "optimistic",
      departure_time: (0, _moment.default)().unix(),
      avoid: "highways"
    }
  });
}
function getPlaceDetail(place_id) {
  return (0, _request.default)({
    url: "/ggMap/getPlaceDetail",
    method: "get",
    params: {
      place_id: place_id
    }
  });
}