"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CardOrderType = exports.CardOrderStatusTrans = exports.CardOrderStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var CardOrderType;
(function (CardOrderType) {
  CardOrderType["Card"] = "CARD";
  CardOrderType["Topup"] = "TOPUP";
})(CardOrderType || (exports.CardOrderType = CardOrderType = {}));
var CardOrderStatus;
(function (CardOrderStatus) {
  CardOrderStatus["PendingPayment"] = "PENDING_PAYMENT";
  CardOrderStatus["Pending"] = "PENDING";
  CardOrderStatus["Complete"] = "COMPLETE";
  CardOrderStatus["Fail"] = "FAIL";
})(CardOrderStatus || (exports.CardOrderStatus = CardOrderStatus = {}));
var CardOrderStatusTrans = exports.CardOrderStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, CardOrderStatus.PendingPayment, {
  label: "Chờ thanh toán",
  color: 'info',
  value: CardOrderStatus.Pending
}), CardOrderStatus.Pending, {
  label: "Đang xử lý",
  color: 'primary',
  value: CardOrderStatus.Pending
}), CardOrderStatus.Complete, {
  label: "Hoàn tất",
  color: 'success',
  value: CardOrderStatus.Complete
}), CardOrderStatus.Fail, {
  label: "Thất bại",
  color: 'danger',
  value: CardOrderStatus.Fail
});