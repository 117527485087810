"use strict";

var _interopRequireWildcard = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.string.includes.js");
var company = _interopRequireWildcard(require("@/api/company"));
var customer = _interopRequireWildcard(require("@/api/customer"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {},
  props: ["listCustomer"],
  data: function data() {
    return {
      dialogLoading: false,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      tempData: {},
      tableData: [],
      avatar: "",
      dialogStatus: "",
      dialogVisible: false,
      loadingButton: false,
      tabActive: "info",
      tableLoading: false,
      total: 0,
      listQuery: {
        limit: 50,
        page: 1
      },
      customers: [],
      formData: {},
      submitLoading: false
    };
  },
  watch: {
    listCustomer: function listCustomer() {
      this.fetchCustomer();
    }
  },
  methods: {
    fetchCustomer: function fetchCustomer(search) {
      var _this = this;
      customer.getList({
        page: 1,
        limit: 50,
        search: search
      }).then(function (res) {
        _this.customers = res.data.data.filter(function (e) {
          return !_this.listCustomer.includes(e.id);
        });
      }).finally(function () {});
    },
    handleCreate: function handleCreate(data) {
      var _this2 = this;
      this.dialogVisible = true;
      this.dialogStatus = "create";
      this.$nextTick(function () {
        _this2.$refs["form"].clearValidate();
        _this2.formData = {};
        _this2.tempData = (0, _objectSpread2.default)({}, data);
      });
    },
    handleUpdate: function handleUpdate(data) {
      var _this3 = this;
      this.dialogVisible = true;
      this.dialogStatus = "edit";
      this.$nextTick(function () {
        _this3.$refs["form"].clearValidate();
        _this3.formData = (0, _objectSpread2.default)({}, data);
      });
    },
    updateData: function updateData() {
      var _this4 = this;
      this.submitLoading = true;
      var data = {
        companyMember: (0, _objectSpread2.default)({}, this.formData)
      };
      company.updateMember(this.formData.id, data).then(function () {
        _this4.$message({
          type: "success",
          message: "Thêm thành công"
        });
        _this4.dialogVisible = false;
        _this4.$emit("update:success");
      }).finally(function () {
        return _this4.submitLoading = false;
      });
    },
    createData: function createData() {
      var _this5 = this;
      this.submitLoading = true;
      var data = {
        customerId: this.formData.customerId,
        member: (0, _objectSpread2.default)({}, this.formData)
      };
      company.addMember(this.tempData.id, data).then(function () {
        _this5.$message({
          type: "success",
          message: "Thêm thành công"
        });
        _this5.dialogVisible = false;
        _this5.$emit("update:success");
      }).finally(function () {
        return _this5.submitLoading = false;
      });
    }
  }
};