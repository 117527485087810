"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _eduConfig = require("@/api/eduConfig.api");
var _edu = require("@/types/edu");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _SingleImage = _interopRequireDefault(require("@/components/Upload/SingleImage.vue"));
var _ConfigModal = _interopRequireDefault(require("./components/ConfigModal.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Tinymce: _Tinymce.default,
    SingleImage: _SingleImage.default,
    ConfigModal: _ConfigModal.default
  },
  data: function data() {
    return {
      eduConfigTrans: _edu.eduConfigTrans,
      formData: {},
      isLoading: false,
      loadingButton: false,
      tip: {},
      dialogStatus: "",
      dialogVisible: false,
      rules: {
        value: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nội dung"
        }
      },
      tableData: [],
      tableDataContent: [],
      type: "" //'content' | 'config',
    };
  },
  created: function created() {
    this.getData();
  },
  methods: {
    handleEdit: function handleEdit(item) {
      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.type = "config";
      this.formData = (0, _objectSpread2.default)({}, item);
      this.$refs.ConfigModal.handleUpdate(item);
    },
    getData: function getData() {
      var _this = this;
      this.isLoading = true;
      _eduConfig.eduConfigApi.findAll().then(function (res) {
        _this.tableData = res.data.eduConfigs;
      }).finally(function () {
        _this.isLoading = false;
      });
    }
  }
};