"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bannerLayoutTrans = exports.BannerType = exports.BannerMode = exports.BannerLayout = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var BannerType;
(function (BannerType) {
  BannerType["All"] = "ALL";
  BannerType["Food"] = "FOOD";
  BannerType["Store"] = "STORE";
  BannerType["PopUp"] = "POPUP";
  BannerType["PopUpPromotion"] = "POPUP_PROMOTION";
  BannerType["BannerPromotion"] = "BANNER_PROMOTION";
  BannerType["Mall"] = "MALL";
  BannerType["IdealPage"] = "IDEAL_PAGE";
  BannerType["IdealProduct"] = "IDEAL_PRODUCT";
})(BannerType || (exports.BannerType = BannerType = {}));
var BannerLayout;
(function (BannerLayout) {
  BannerLayout["Top"] = "TOP";
  BannerLayout["Bottom"] = "BOTTOM";
})(BannerLayout || (exports.BannerLayout = BannerLayout = {}));
var bannerLayoutTrans = exports.bannerLayoutTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, BannerLayout.Top, {
  title: "Đầu trang",
  value: BannerLayout.Top
}), BannerLayout.Bottom, {
  title: "Cuối trang",
  value: BannerLayout.Bottom
});
var BannerMode;
(function (BannerMode) {
  BannerMode["Post"] = "POST";
  BannerMode["Link"] = "LINK";
  BannerMode["IdealPage"] = "IDEAL_PAGE";
  BannerMode["IdealProduct"] = "IDEAL_PRODUCT";
})(BannerMode || (exports.BannerMode = BannerMode = {}));