"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cancel = cancel;
exports.findAll = findAll;
exports.orderApi = void 0;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
// @ts-check

function findAll(listQuery) {
  return (0, _request.default)({
    url: "/orders",
    params: listQuery
  });
}
function update(orderId, data) {
  return (0, _request.default)({
    url: "/orders/".concat(orderId, "/status/update"),
    method: "post",
    data: data
  });
}
function cancel(orderId) {
  return (0, _request.default)({
    url: "/orders/".concat(orderId, "/cancel"),
    method: "post"
  });
}
var orderApi = exports.orderApi = {
  findAll: findAll,
  update: update,
  findOne: function findOne(orderId) {
    return (0, _request.default)({
      url: "/orders/".concat(orderId)
    });
  }
};