import { render, staticRenderFns } from "./SchoolAddressModal.vue?vue&type=template&id=f2b58666"
import script from "./SchoolAddressModal.vue?vue&type=script&lang=ts"
export * from "./SchoolAddressModal.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/mac/Documents/project/028/028admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f2b58666')) {
      api.createRecord('f2b58666', component.options)
    } else {
      api.reload('f2b58666', component.options)
    }
    module.hot.accept("./SchoolAddressModal.vue?vue&type=template&id=f2b58666", function () {
      api.rerender('f2b58666', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/schoolAddress/components/SchoolAddressModal.vue"
export default component.exports