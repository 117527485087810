"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _WithdrawCompany = _interopRequireDefault(require("./WithdrawCompany.vue"));
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    WithdrawCompany: _WithdrawCompany.default
  },
  data: function data() {
    return {
      tabName: "promotion",
      BalanceType: BalanceType
    };
  },
  methods: {}
};