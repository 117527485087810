"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _RightPanel = _interopRequireDefault(require("@/components/RightPanel"));
var _components = require("./components");
var _ResizeHandler = _interopRequireDefault(require("./mixin/ResizeHandler"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Layout",
  components: {
    AppMain: _components.AppMain,
    Navbar: _components.Navbar,
    RightPanel: _RightPanel.default,
    Settings: _components.Settings,
    Sidebar: _components.Sidebar,
    TagsView: _components.TagsView
  },
  mixins: [_ResizeHandler.default],
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSettings: function showSettings(state) {
      return state.settings.showSettings;
    },
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    },
    fixedHeader: function fixedHeader(state) {
      return state.settings.fixedHeader;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    }
  }),
  created: function created() {
    var _this = this;
    setTimeout(function () {
      _this.dragOneSignal();
    }, 500);
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", {
        withoutAnimation: false
      });
    },
    dragOneSignal: function dragOneSignal() {
      var vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      var vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      var offsetX;
      var offsetY;
      var oneSignal = document.getElementById("onesignal-bell-container");
      var app = document.querySelector("body");
      oneSignal.draggable = true;
      var onDragStart = function onDragStart(ev) {
        var rect = ev.target.getBoundingClientRect();
        offsetX = ev.clientX - rect.x;
        offsetY = ev.clientY - rect.y;
      };
      var drop_handler = function drop_handler(ev) {
        ev.preventDefault();
        oneSignal.style.right = "unset";
        oneSignal.style.bottom = "unset";
        oneSignal.style.left = ev.clientX - offsetX + "px";
        oneSignal.style.top = ev.clientY - offsetY + "px";
        localStorage.setItem("onesignal-right", vw - ev.clientX - offsetX - 15);
        localStorage.setItem("onesignal-bottom", vh - ev.clientY - offsetY);
        app.appendChild(document.getElementById("onesignal-bell-container"));
      };
      var dragover_handler = function dragover_handler(ev) {
        ev.preventDefault();
        ev.dataTransfer.dropEffect = "move";
      };
      oneSignal.addEventListener("dragstart", onDragStart);
      app.addEventListener("drop", drop_handler);
      app.addEventListener("dragover", dragover_handler);
    }
  }
};