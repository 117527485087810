"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _admin = _interopRequireDefault(require("./admin"));
//
//
//
//
//
//
var _default = exports.default = {
  name: "Dashboard",
  components: {
    AdminDashboard: _admin.default
  },
  data: function data() {
    return {
      currentRole: "adminDashboard"
    };
  }
};