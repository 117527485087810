"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cashLast30 = cashLast30;
exports.companyLast30 = companyLast30;
exports.customerLast = customerLast;
exports.driverIncomesLast30 = driverIncomesLast30;
exports.driverLast = driverLast;
exports.getDashboard = getDashboard;
exports.ordersLast30 = ordersLast30;
exports.revenuesLast30 = revenuesLast30;
exports.saleVehicle = saleVehicle;
exports.salesLast30 = salesLast30;
exports.taxDriverLast30 = taxDriverLast30;
exports.top5Customers = top5Customers;
exports.top5Driver = top5Driver;
exports.useAppDriverLast30 = useAppDriverLast30;
exports.vatLast30 = vatLast30;
exports.vnpayLast30 = vnpayLast30;
var _request = _interopRequireDefault(require("@/utils/request"));
function getDashboard(params) {
  return (0, _request.default)({
    url: "/dashboard",
    method: "get",
    params: params
  });
}
function ordersLast30(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/ordersLast30",
    method: "get",
    params: listQuery
  });
}
function saleVehicle(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/saleVehicle",
    method: "get",
    params: listQuery
  });
}
function salesLast30(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/salesLast30",
    method: "get",
    params: listQuery
  });
}
function customerLast(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/customersLast30",
    method: "get",
    params: listQuery
  });
}
function driverLast(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/driversLast30",
    method: "get",
    params: listQuery
  });
}
function revenuesLast30() {
  return (0, _request.default)({
    url: "/dashboard/revenuesLast30",
    method: "get"
  });
}
function driverIncomesLast30(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/driverIncomesLast30",
    method: "get",
    params: listQuery
  });
}
function top5Driver(params) {
  return (0, _request.default)({
    url: "/dashboard/top5DriversThisMonth",
    params: params
  });
}
function top5Customers(params) {
  return (0, _request.default)({
    url: "/dashboard/top5CustomersThisMonth",
    params: params
  });
}
function cashLast30(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/cashLast30",
    method: "get",
    params: listQuery
  });
}
function vnpayLast30(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/vnpayLast30",
    method: "get",
    params: listQuery
  });
}
function useAppDriverLast30(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/useAppDriverLast30",
    method: "get",
    params: listQuery
  });
}
function companyLast30(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/companyLast30",
    method: "get",
    params: listQuery
  });
}
function taxDriverLast30(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/taxDriverLast30",
    method: "get",
    params: listQuery
  });
}
function vatLast30(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/vatLast30",
    method: "get",
    params: listQuery
  });
}