"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cancel = cancel;
exports.complete = complete;
exports.create = create;
exports.createPromotion = createPromotion;
exports.findAll = findAll;
exports.findAllTotal = findAllTotal;
var _request = _interopRequireDefault(require("@/utils/request"));
function findAll(listQuery) {
  return (0, _request.default)({
    url: "/withdraws",
    method: "get",
    params: listQuery
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/withdraws",
    method: "post",
    data: data
  });
}
function createPromotion(data) {
  return (0, _request.default)({
    url: "/withdraws/promotion",
    method: "post",
    data: data
  });
}
function cancel(id, data) {
  return (0, _request.default)({
    url: "/withdraws/".concat(id, "/cancel"),
    method: "post",
    data: data
  });
}
function complete(id, data) {
  return (0, _request.default)({
    url: "/withdraws/".concat(id, "/complete"),
    method: "post",
    data: data
  });
}
function findAllTotal(listQuery) {
  return (0, _request.default)({
    url: "/withdraws/total",
    method: "get",
    params: listQuery
  });
}