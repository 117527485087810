"use strict";

var _interopRequireWildcard = require("/Users/mac/Documents/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _appFeatureConfigDetail = require("@/api/appFeatureConfigDetail");
var vehicleCity = _interopRequireWildcard(require("@/api/vehicle-city"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["cities"],
  data: function data() {
    return {
      form: {},
      visible: false,
      loading: false,
      rules: {
        toCityId: {
          required: true,
          trigger: "submit",
          message: "Vui lòng chọn tỉnh thành"
        }
      }
    };
  },
  methods: {
    handleOpen: function handleOpen(cityId) {
      this.visible = true;
      this.form.fromCityId = cityId;
    },
    handleSubmit: function handleSubmit() {
      var _this = this;
      if (this.form.fromCityId == this.form.toCityId) {
        return this.$message.error("Tỉnh thành phải khác nhau!");
      }
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this.loading = true;
          _appFeatureConfigDetail.appFeatureConfigDetailApi.cloneCityToCity({
            fromCityId: _this.form.fromCityId,
            toCityId: _this.form.toCityId
          }).then(function () {
            _this.$message.success("Sao chép thành công!");
            _this.visible = false;
          }).finally(function () {
            return _this.loading = false;
          });
        }
      });
    }
  }
};